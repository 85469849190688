import * as React from "react";
import axios from "axios";
import URL from '../../Constants/links';
import message from '../../Constants/message'
const sendemail = async (data) =>
{
    // try {
    //     const data = await axios.get(URL.api_url+URL.brands)
    //     .then((data)=>
    //       {
    //         return {data:data.data};
    //       }
    //     )
    //     .catch((error)=>
    //       {
    //         return {status:400,msg:message.serverError};
    //       }
    //     );
    //     return data;
    //   } catch (error) {
    //     return {status:400,msg:message.serverError};
    //   }
      
      try {
        const response = await fetch(URL.api_url+URL.sendemail, {
          method: 'POST',
          body: data,
      });
  
      if (response.ok) {
        return {data:'Email sent successfully!'};
         // alert('Email sent successfully!');
      } else {
       // return {data:'Failed to send email.'};
        return {status:400,msg:message.serverError};
          //alert('Failed to send email.');
      }
  
      } catch (error) {
        console.error('Error sending email:', error);
        return {status:400,msg:message.serverError};
        //alert('Error sending email');
      }
}

export  {sendemail};
// src/components/ContactForm.js
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import DataContext from '../../context/DataContext';
import { sendemail } from '../../Services/Quote/SendEmail';
import { ToastContainer, toast } from 'react-toastify';
import PdfCardForSumAndBom from "./PdfCardForSumAndBom";
import { styles } from '../../Common/utils';
import Loader from '../Loader/loader';


const ContactForm = () => {
  const { 
    userDetails, setUserDetails, blobpdf, setBlobpdf, 
    selectedItems, areaControllingItems, totalPrice, selectedBrandName, selectedBrand, essentialItem, 
    installationCommissioningItem,floorTrasnDetails,floors,buildingTypeName,constructionStageName
  } = useContext(DataContext);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const [isHighlighted, setIsHighlighted] = useState(true);
  const [globalLoader, setGlobalLoader] = useState(true);


  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };


  useEffect(() => {
    setGlobalLoader(false); 
    // Target the div outside the root element
    return SetBackground();
  }, [isHighlighted]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }
    // alert("wow...");
    setGlobalLoader(true); 

    const data = new FormData();
    data.append('to', userDetails.email);
    data.append('firstName', userDetails.firstName);
    data.append('lastName', userDetails.lastName);
    data.append('company', userDetails.company);
    data.append('city', userDetails.city);
    data.append('mobile', userDetails.mobile);
    data.append('email', userDetails.email);

     // If blob exists, append it to the form data
    if (blobpdf) {
      data.append('attachment', blobpdf, 'quote.pdf');
    }

     const emailstatus = await sendemail(data);
     console.log(emailstatus)
        if (emailstatus.status === 400) 
          { 
            alert(emailstatus.msg);     
            toast.error(emailstatus.msg) 
          } 
        else { 
          //alert(emailstatus.data);
          window.location.href = "https://automateo.de/thank-you";                          
        }
  };

  function SetBackground() {
    const outsideDiv = document.getElementById('bgsection');
  
    // Update the class of the outside div
    if (outsideDiv) {
        outsideDiv.classList.add('screen4-bg');
    }
  
    // Clean up when the component unmounts
    return () => {
      if (outsideDiv) {
        outsideDiv.classList.remove('screen4-bg');
      }
    };
  }

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
    <div class="main-container">
    <Loader toggle={globalLoader} />
      <div class="text-wrapper-4">Contact Us</div>
      <div class="contact-form">
      <form onSubmit={handleSubmit}>
        <div class="form-group">
        <label for="first-name">First Name</label>
        <input type="text" name="firstName" placeholder="First Name" value={userDetails.firstName} onChange={handleInputChange} required />
        </div>
        <div class="form-group">
                            <label for="last-name">Last Name</label>
        <input type="text" name="lastName" placeholder="Last Name" value={userDetails.lastName} onChange={handleInputChange} required />
        </div>
        <div class="form-group">
                            <label for="Company">Company</label>
        <input type="text" name="company" placeholder="Company (Optional)" value={userDetails.company} onChange={handleInputChange} />
        </div>
        <div class="form-group">
                            <label for="City">City</label>
        <input type="text" name="city" placeholder="City (Optional)" value={userDetails.city} onChange={handleInputChange} />
        </div>

        <div class="form-group">
        <label for="Email">Email</label>
        <input type="email" name="email" placeholder="Email" value={userDetails.email} onChange={handleInputChange} required />
        </div>
        <div class="form-group">
        <label for="Mobile">Mobile</label>
        <input type="tel" name="mobile" placeholder="Mobile" value={userDetails.mobile} onChange={handleInputChange} required />
        </div>
        {/* Add captcha */}
        <div>
        <ReCAPTCHA
          sitekey="6LenTVkqAAAAANZQ5cYEqkUy8j2n8676QimxQsnE"
          onChange={handleRecaptchaChange}
        />
         {/* <input type="file" onChange={handleFileChange} /> */}
      </div>
        <button type="submit">Send</button>
        

      
      </form>

      <div id='pdfbuttons' style={styles.pdfbuttons}>
        {selectedItems && selectedItems.length > 0 && (
        <PdfCardForSumAndBom  title="Automateo Generate Quote" 
        selecteditems={selectedItems} 
        selectedbrand={selectedBrand} 
        selectedbrandname={selectedBrandName} 
        totalprice={totalPrice} 
        areaControllingItems={areaControllingItems}
        essentialItem={essentialItem}
        installationCommissioningItem={installationCommissioningItem}
        floorTrasnDetails={floorTrasnDetails}
        floors={floors}
        buildingTypeName={buildingTypeName}
        constructionStageName={constructionStageName}
        userDetails={userDetails}
        />
        )}
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactForm;

import * as React from "react";
import axios from "axios";
import URL from '../Constants/links'; 
import message from '../Constants/message'
const getUerSettings = async () =>{
  try {
    const data = await axios.get(URL.api_url+URL.userSettings+localStorage.getItem('id'))
    .then((res)=>
      {
        return {data:res};
      }
    )
    .catch((error)=>
      {
        return {status:400,msg:message.serverError};
      }
    ); 
    return data;
  } catch (error) {
    return {status:400,msg:message.serverError};
}       
   
}
const saveSetting = async (json) =>
{
    if(json.lockKey1 == json.lockKey2)
    {
      return {status:400,msg:message.lockKeySameError};
    }
    try {
        const data = await axios.post(URL.api_url+URL.saveSetting,json)
        .then((res)=>
          {
            // const msg = json.type ==1 ?  message.userSettingUpdated : message.userPrefrenceUpdated;
            return {data:res,msg:message.userSettingUpdated,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

export  {getUerSettings,saveSetting};
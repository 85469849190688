import React, { Fragment, useState, useEffect , useContext } from 'react'
import DataContext from '../../context/DataContext';
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import logo from '../../Assets/img/logo.png'
import { generateInvoiceNumber, getCurrentDateTime,formatCurrency, comparator,generateId,areaItemCodeOrder, getItemCodeOrder } from '../../Common/utils'


  const InvoiceSumAndBom = (
    {
      selecteditems, 
      selectedbrand,
      selectedbrandname, 
      totalprice, 
      areaControllingItems, 
      essentialItem,
      installationCommissioningItem,
      floorTrasnDetails,
      floors,
      buildingTypeName,
      constructionStageName,
      userDetails
     }) => {

   // const {totalPrice} = useContext(DataContext);

    const [quoteNumber, setQuoteNumber] = useState('');
    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        setQuoteNumber(generateInvoiceNumber());
        setCurrentDateTime(getCurrentDateTime());
      }, []);

      const getBedroomQuantity = (selectedItems) => {
        const bedroomItem = selectedItems.find(item => item.ItemCode === 'bedr');
        const quantity = bedroomItem ? bedroomItem.Quantity : 0; // Default to 0 if not found
        return quantity;  
      };


    const styles = StyleSheet.create({
        row: {  display: 'flex',   flexDirection: 'row',  marginBottom: 1,   },
        label: {  width: '30%', fontWeight: 'bold',   },
        value: {  width: '70%',  textAlign: 'right' },

        page: {fontSize: 11,paddingTop: 20,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column' },

        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },

        titleContainer: {flexDirection: 'row',marginTop: 24},
        
        logo: { width: 90 },

        reportTitle: {  fontSize: 16,  textAlign: 'center' },

        addressTitle : {fontSize: 11,fontStyle: 'bold'}, 
        
        invoice : {fontWeight: 'bold',fontSize: 20},
        
        invoiceNumber : {fontSize: 11,fontWeight: 'bold'}, 
        
        address : { fontWeight: 400, fontSize: 10},
        
        theader : {marginTop : 10,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderCenter : {textAlign: 'center', marginTop : 10,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderRight : {textAlign: 'right', marginTop : 10,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingRight: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderTotalRight : {textAlign: 'right', fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingRight: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theaderTotalLeft : {textAlign: 'left', fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        typeheader : {marginTop : 5,fontSize : 9,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#f1f1f1',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theader2 : { flex:2, borderRightWidth:1, borderBottomWidth:1},

        tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},

        total:{ fontStyle: 'bold', fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1.5, borderColor : 'whitesmoke', borderBottomWidth:1},

        tbody2:{ flex:2, borderRightWidth:1, },

        tbodyCenter:{ textAlign: 'center', fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},
        
        tbodyRight:{ textAlign: 'right', fontSize : 9, paddingTop: 4 , paddingRight: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},
       
    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.reportTitle}>Automateo</Text>
            </View>
        </View>
    );

    const SummaryTitle = () => (
       <View style={[styles.titleContainer]}>
          <View style={styles.spaceBetween}>
              <Text style={styles.reportTitle}>Summary</Text>
          </View>
       </View>
  );

  const BomTitle = () => (
    <View style={styles.titleContainer}>
        <View style={styles.spaceBetween}>
            <Text style={styles.reportTitle}>BOM (Bill of materials)</Text>
        </View>
    </View>
);

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                
                <View>
                    <Text style={styles.invoice}>Quotation </Text>
                    <Text style={styles.invoiceNumber}>Quote number: {quoteNumber} </Text>
                </View>
                <View>
                <Text style={styles.invoice}> </Text>
                <Text style={styles.addressTitle}>Date: {currentDateTime}</Text>                
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>               

   <View style={{ maxWidth: 160 }}>
   
   {userDetails.firstName && (
      <View style={[styles.row,styles.address]}>
        <Text style={ {  width: '20%', color: "#080101"   }}>Name:</Text>
        <Text style={{  width: '80%',  textAlign: 'right' }}>{userDetails.firstName} {userDetails.lastName}</Text>
      </View>
      )}

      {userDetails.company && (
      <View style={[styles.row,styles.address]}>
        <Text style={ {  width: '40%', fontWeight: 'bold',  color: "#080101"  }}>Company:</Text>
        <Text style={{  width: '60%',  textAlign: 'right' }}>{userDetails.company}</Text>
      </View>
      )}

      {userDetails.city && (
      <View style={[styles.row,styles.address]}>
        <Text style={ {  width: '20%', fontWeight: 'bold', color: "#080101"   }}>City:</Text>
        <Text style={{  width: '80%',  textAlign: 'right' }}>{userDetails.city}</Text>
      </View>
      )}

     {userDetails.email && (
      <View style={[styles.row,styles.address]}>
        <Text style={ {  width: '20%', fontWeight: 'bold', color: "#080101"  }}>Email:</Text>
        <Text style={{  width: '80%',  textAlign: 'right' }}>{userDetails.email}</Text>
      </View>
      )}

     {userDetails.mobile && (
      <View style={[styles.row,styles.address]}>
        <Text style={ {  width: '30%', fontWeight: 'bold', color: "#080101"   }}>Mobile:</Text>
        <Text style={{  width: '70%',  textAlign: 'right' }}>{userDetails.mobile}</Text>
      </View>
      )}
      
    </View>

    <View style={{ maxWidth: 150 }}>
        <View style={[styles.row,styles.address]}>
        <Text style={{width: '60%',  color: "#080101"}}>Buiding Type:</Text>
        <Text style={{  width: '40%',  textAlign: 'right' }}>{buildingTypeName}</Text>
        </View>
        <View style={[styles.row,styles.address]}>
        <Text style={{width: '60%', color: "#080101"}}>Number of Floors: </Text>
        <Text style={{  width: '40%',  textAlign: 'right' }}>{floors}</Text>
        </View>
        <View style={[styles.row,styles.address]}>
        <Text style={{width: '60%', color: "#080101"}}>Number of Rooms:</Text>
        <Text style={{  width: '40%',  textAlign: 'right' }}>{getBedroomQuantity(selecteditems)}</Text>
        </View>
        <View style={[styles.row,styles.address]}>
        <Text style={{width: '30%', color: "#080101"}}>Status: </Text>
        <Text style={{  width: '70%',  textAlign: 'right' }}>{constructionStageName}</Text>
        </View>
        <View style={[styles.row,styles.address]}>
        <Text style={{width: '30%', color: "#080101"}}>Brand: </Text>
        <Text style={{  width: '70%',  textAlign: 'right' }}>{selectedbrandname}</Text>
        </View>                    
    </View>
            </View>
        </View>
    );


    const TableHeadSummary = () => (
        <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Items ({selectedbrandname})</Text>   
            </View>           
            <View style={styles.theaderCenter}>
                <Text>Quantity</Text>   
            </View>
            <View style={styles.theaderRight}>
                <Text>Unit Price</Text>   
            </View>
            <View style={styles.theaderRight}>
                <Text>Amount (INR)</Text>   
            </View>
        </View>
    );

    const TableHeadBom = () => (
      <View style={{ width:'100%', flexDirection :'row'}}>
          <View style={[styles.theader, styles.theader2]}>
              <Text >Name</Text>   
          </View>           
          <View style={styles.theaderRight}>
              <Text>Quantity</Text>   
          </View>
      </View>
  );

  const TableHeadSummaryNew = () => (
    <View style={{ width:'100%', flexDirection :'row'}}>
        <View style={[styles.theader, styles.theader2]}>
            <Text >Item Name</Text>   
        </View>           
        <View style={styles.theaderRight}>
            <Text>Price</Text>   
        </View>
    </View>
);

    const renderItemsWithHeaders = (selectedItems, selectedBrandName) => {
        let headers = {
          efit: false,
          dait: false,
          opit: false,
          miit: false,
        };

        if(!selectedItems)
        {
            return;
        }
      
        return selectedItems?.sort(comparator).map((item, index) => {
          let header = null;
      
          if (!headers[item.ItemTypeCode]) {
            switch (item.ItemTypeCode) {
              case 'efit':
                header = (
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>
                        <View style={styles.typeheader}>
                           <Text colSpan="4">Essential Items</Text>
                      </View>
                    </View>
                  );
                break;
              case 'dait':
                header = (                    
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>
                        <View style={styles.typeheader}>
                           <Text colSpan="4">Area</Text>
                      </View>
                    </View>
                  );
                break;
              case 'opit':
                header = (
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>                     
                      <View style={styles.typeheader}>
                         <Text colSpan="4">Optional Products</Text>
                      </View>
                    </View>
                  );
                break;
              case 'miit':
                header = (
                    <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>                      
                      <View style={styles.typeheader}>
                       <Text colSpan="4">Miscellaneous Items</Text>
                      </View>
                    </View>
                  );
                break;
              default:
                break;
            }
            headers[item.ItemTypeCode] = true;
          }
      
          return (
            <React.Fragment key={index}>
              {header}
              <View style={{ width:'100%', flexDirection :'row'}}>
                 <View style={[styles.tbody, styles.tbody2]}>
                     <Text >{item.ItemName}</Text>   
                 </View>
                 <View style={styles.tbodyCenter}>
                     <Text>{item.Quantity}</Text>   
                 </View>
                 <View style={styles.tbodyRight}>
                     <Text>{formatCurrency(item[selectedBrandName])} </Text>   
                 </View>                 
                 <View style={styles.tbodyRight}>
                     <Text>{formatCurrency(item[selectedBrandName] * item.Quantity)}</Text>   
                 </View>
             </View>
            </React.Fragment>
          );
        });
      };

    const TableBodySummary = () => (        
        renderItemsWithHeaders(selecteditems, selectedbrandname)
     );

   

    const TableTotalSummary = () => (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text>Total</Text>   
            </View>
            <View style={styles.theaderRight}>
                <Text>                
                    {formatCurrency(totalprice)}
                </Text>  
            </View>
        </View>
    );




  const EssentialItemBom = () => (
    <View style={{ width:'100%', flexDirection :'row'}}>
               <View style={[styles.tbody, styles.tbody2]}>
               <Text>{essentialItem?.ItemName ?? 'No Item Name'}</Text>
               </View>
               <View style={styles.tbodyRight}>
                   <Text>1</Text>   
               </View>              
           </View>
);

const EssentialItemSummaryNew = () => (
  <>
  <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>
                        <View style={styles.typeheader}>
                           <Text colSpan="2">Essential Items</Text>
                      </View>
                    </View> 
  <View style={{ width:'100%', flexDirection :'row'}}>
             <View style={[styles.tbody, styles.tbody2]}>
             <Text>{essentialItem?.ItemName ?? 'No Item Name'}</Text>
             </View>
             <View style={styles.tbodyRight}>
                 <Text>
                 {formatCurrency(essentialItem[selectedbrandname] * essentialItem.Quantity)}
                  </Text>   
             </View>              
         </View>
  </>
);

const InstallationCommissioningItemSummaryNew = () => (
  <> 
  <View style={{ width:'100%', flexDirection :'row',backgroundColor : '#f1f1f1',borderColor : 'whitesmoke'}}>
             <View style={[styles.tbody, styles.tbody2]}>
             <Text>{installationCommissioningItem?.ItemName ?? 'No Item Name'}</Text>
             </View>
             <View style={[styles.tbodyRight]}>
                 <Text>
                 {formatCurrency(installationCommissioningItem[selectedbrandname] * installationCommissioningItem.Quantity)}
                  </Text>   
             </View>              
         </View>
  </>
);



  const MiscellaneousItemsSummaryNew = () => {
    // Assuming selecteditems is available in the component's scope
    const miisItems = selecteditems.filter(
      (selectedItem) => selectedItem.ItemTypeCode === 'miit'
    );
  
    if (!miisItems || miisItems.length === 0) {
      return null; // Or return an empty View: <View />
    }
  
    return (
      <>
        {/* Header Row */}
        {miisItems.length > 0 && (
           <View key={'header-efit'} style={{ width:'100%', flexDirection :'row'}}>
           <View style={styles.typeheader}>
              <Text colSpan="2">Miscellaneous Items</Text>
         </View>
       </View> 
        )}
  
        {/* Item Rows */}
        {miisItems.map((item) => (
          <View key={item.id} style={{ width:'100%', flexDirection :'row'}}>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text >{item.ItemName}</Text>
            </View>
            <View style={styles.tbodyRight}>
              <Text > {formatCurrency(item[selectedbrandname] * item.Quantity)}</Text>
            </View>
          </View>
        ))}
      </>
    );
  };


  const FloorAreaItemsSummaryNew = () => {
    return (
      <React.Fragment>
        {floorTrasnDetails.sort((a, b) => a.Id - b.Id).map((floor, floorIndex) => (
          <React.Fragment key={floorIndex}>
            
            {/* Render the floor (essential item) */}
            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View style={styles.typeheader}>
                <Text colSpan="2">{floor.ItemName}</Text>
              </View>
            </View>
  
            {/* Sort SelectedAreas based on areaItemCodeOrder and OrderByNum */}
            {floor.SelectedAreas
              .sort((a, b) => {
                const codeOrderA = getItemCodeOrder(a.ItemCode);
                const codeOrderB = getItemCodeOrder(b.ItemCode);
                if (codeOrderA === codeOrderB) {
                  return a.OrderByNum - b.OrderByNum;
                }
                return codeOrderA - codeOrderB;
              })
              .map((area, areaIndex) => {
                // Calculate total price for the area
                const areaPrice = parseFloat(area[selectedbrandname]) || 0;
                const selectedItemsPrice = area.SelectedItems.reduce(
                  (total, item) =>
                    total +
                    (parseFloat(item[selectedbrandname]) || 0) * (item.Quantity || 1),
                  0
                );
                const totalAreaPrice = areaPrice + selectedItemsPrice;
  
                // Build a string of item names and prices
                const selectedItemsNamesAndPrices = area.SelectedItems.length > 0
                  ? area.SelectedItems.map(item => {
                      const itemPrice = parseFloat(item[selectedbrandname]) || 0;
                      return `${item.ItemName} (${formatCurrency(itemPrice)})`;
                    }).join(', ')
                  : '';
  
                // Generate the areaTitle with area price and individual item prices
                const areaTitle = selectedItemsNamesAndPrices
                  ? `${area.ItemName} (${formatCurrency(areaPrice)}) - ${selectedItemsNamesAndPrices}`
                  : `${area.ItemName} (${formatCurrency(areaPrice)})`;
  
                return (
                  <React.Fragment key={`${floorIndex}-${areaIndex}`}>
                    {/* Render Selected Areas */}
                    <View style={{ width: '100%', flexDirection: 'row' }}>
                      <View style={[styles.tbody, styles.tbody2]}>
                        <Text>{area.ItemName} - {area.OrderByNum}</Text>
                      </View>
                      <View style={styles.tbodyRight}>
                        <Text>{formatCurrency(totalAreaPrice)}</Text>
                      </View>
                    </View>
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };
  


  const renderAreaControllingItemsBom = () => {

    const newBom = areaControllingItems.reduce((acc, controlItem) => {
      const matchingItems = selecteditems.filter(
          (selectedItem) =>
              selectedItem.ItemCode === controlItem.AreaItemCode &&
              controlItem.BrandCode === selectedbrand //selected brand
      );

      const totalQuantity = matchingItems.reduce(
          (sum, item) => sum + item.Quantity, 0
      );

      if (totalQuantity > 0) {

            const existingItem = acc.find(item => item.Name === controlItem.ControllingItemName);
            
            if (existingItem) {
                // If the item with the same name already exists, sum the quantities
                existingItem.Quantity += controlItem.ControllingItemQuantity * totalQuantity;
            } else {
                // Otherwise, add a new item to the bom
                acc.push({
                    Id: generateId(),
                    Name: controlItem.ControllingItemName,
                    Quantity: controlItem.ControllingItemQuantity * totalQuantity
                });
            }
      }

      return acc;
  }, []);
    
      return newBom?.map((item, index) => {
       
        return (
          <React.Fragment key={index}>
            <View style={{ width:'100%', flexDirection :'row'}}>
               <View style={[styles.tbody, styles.tbody2]}>
                   <Text >{item.Name}</Text>   
               </View>
               <View style={styles.tbodyRight}>
                   <Text>{item.Quantity}</Text>   
               </View>              
           </View>
          </React.Fragment>
        );
      });
    };

    const renderOptionalItemsBom = () => {
  
      const opitItems = selecteditems.filter(
        (selectedItem) =>
            selectedItem.ItemTypeCode === 'opit'
      );
  
      
        return opitItems?.map((item, index) => {
         
          return (
            <React.Fragment key={index}>
              <View style={{ width:'100%', flexDirection :'row'}}>
                 <View style={[styles.tbody, styles.tbody2]}>
                     <Text >{item.ItemName}</Text>   
                 </View>
                 <View style={styles.tbodyRight}>
                     <Text>{item.Quantity}</Text>   
                 </View>              
             </View>
            </React.Fragment>
          );
        });
      };

    const renderMiscellaneousItemsBom = () => {

        const miisItems = selecteditems.filter(
          (selectedItem) =>
              selectedItem.ItemTypeCode === 'miit'
        );
        
          return miisItems?.map((item, index) => {
           
            return (
              <React.Fragment key={index}>
                <View style={{ width:'100%', flexDirection :'row'}}>
                   <View style={[styles.tbody, styles.tbody2]}>
                       <Text >{item.ItemName}</Text>   
                   </View>
                   <View style={styles.tbodyRight}>
                       <Text>{item.Quantity}</Text>   
                   </View>              
               </View>
              </React.Fragment>
            );
          });
        };

       

        const TableBodyBom = () => (
          <>
              {renderAreaControllingItemsBom()}
              {renderOptionalItemsBom()}
              {renderMiscellaneousItemsBom()}
          </>
      );

    

    return (
        <Document>

            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/> 
                <SummaryTitle/>
                <TableHeadSummaryNew/>
                <EssentialItemSummaryNew/>
                <FloorAreaItemsSummaryNew/>  
                <MiscellaneousItemsSummaryNew/>
                <InstallationCommissioningItemSummaryNew/>
                <TableTotalSummary/>
            </Page>

            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/> 
                <BomTitle/>
                <TableHeadBom/>
                <EssentialItemBom/>
                <TableBodyBom/>  
            </Page>

            

            {/* <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/>
                <SummaryTitle/>
                <TableHeadSummary/>
                <TableBodySummary/>                
                <TableTotalSummary/>
            </Page> */}
        </Document>
          
    )
}

export default InvoiceSumAndBom
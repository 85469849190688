import * as React from "react";
import { useEffect, useState } from 'react';
import images from '../../../Constants/image'
import Sidebar from '../../../layout/SidebarAdmin';
import checkAuth from '../../../middleware/checkAuth'
import { create } from '../../../Services/Admin/User';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useLocation, useParams  } from "react-router-dom";
import message from '../../../Constants/message'
import Loader from '../../Loader/loader';
import {mmToCm,cmToMm} from '../../../Helpers/helper';
function Main() {
    
    const navigate = useNavigate();
    const params = useParams();
    const {state} = useLocation();
    const [loader, setLoader] = useState(false);
    
    const [tagNo, setTagNo] = useState('');
    const [alternativeTagNo, setAlternativeTagNo] = useState('');
    const [FName, setFName] = useState('');
    const [LName, setLName] = useState('');
    const [active, setActive] = useState(true);
    const [userType, setUserType] = useState('');
    const [userId, setUserId] = useState(0);
    const [submitType, setSubmitType] = useState('0');
    const [IsANNYUser, setIsANNYUser] = useState();
    const [CompType, setCompType] = useState(localStorage.getItem('comptype'));
    const [globalLoader, setGlobalLoader] = useState(true);
    useEffect(()=>{
        if(state!=null) { 
            console.log(state.data)
            setSubmitType(state.data.UserId);setUserId(state.data.UserId); setTagNo(state.data.TagNo); setAlternativeTagNo(state.data.AlternativeTagNo); setFName(state.data.FName);setLName(state.data.LName);setActive(state.data.Active);setUserType(state.data.UserType);
            setIsANNYUser(state.data.IsANNYUser);
        } 
        else

        {
            setIsANNYUser(0);
        }
        setGlobalLoader(false);
    },[globalLoader])

    

    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault()
        localStorage.getItem('id') != null  ? localStorage.getItem('id') :  navigate("/login");
        const data = {"UserId":userId,"TagNo":e.target.tagNo.value,"AlternativeTagNo":e.target.alternativeTagNo.value,"FName":e.target.FName.value,"LName":e.target.LName.value,"Active":e.target.active.value,"UserType":e.target.userType.value,"IsANNYUser":IsANNYUser};
        const res = await create(data);
        res.status == 200 ? toast.success(res.msg) : toast.error(res.msg) ;
        setLoader(false); 
        if (submitType == 0 && res.status == 200) { setFName(); setLName(); setTagNo(); setAlternativeTagNo(); setFName();setLName(); }
    }


    return (
        <>
        <Loader toggle={globalLoader} />
        <ToastContainer  position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />          
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                                <div className="intro-y flex items-center h-10">
                                    <h2 className="text-lg font-medium truncate mr-5">
                                    {submitType==0?'Add':'Update'} User
                                    </h2>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-5">
                                    <div className="intro-y col-span-12 lg:col-span-6">


                                        <div className="intro-y box">
                                            <div id="input" className="p-5 table w-full">
                                                <div className="preview">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className={` ${IsANNYUser ==1 ? 'hidden' : ''} `}>
                                                            <label htmlFor="regular-form-2" className="form-label">First Name <font color='red'> * </font> </label>
                                                            <input value={FName}  onChange={(e)=>setFName(e.target.value)}   name="FName" id="regular-form-2" type="text" className="form-control"  placeholder="First Name"  required />
                                                            
                                                        </div>
                                                        <div className={` ${IsANNYUser ==1 ? 'hidden' : ' mt-3 position-relative '} `}>
                                                            <label htmlFor="regular-form-2" className="form-label">Last Name <font color='red'> * </font> </label>
                                                            <input value={LName}  onChange={(e)=>setLName(e.target.value)}   name="LName" id="regular-form-2" type="text" className="form-control"  placeholder="Last Name"  required/>
                                                            
                                                        </div>                                                             
                                                        <div className="mt-3 position-relative">
                                                            <label htmlFor="regular-form-1" className="form-label">Tag No.<font color='red'> *</font></label>
                                                            <input value={tagNo}  onChange={(e)=>setTagNo(e.target.value)} name="tagNo" step='1' id="regular-form-1" type="number" className="form-control"  placeholder="Tag No."  required />
                                                        </div>                                                                                                                
                                                        <div className="mt-3 position-relative">
                                                            <label htmlFor="regular-form-2" className="form-label">Alternative Tag No. </label>
                                                            <input value={alternativeTagNo}  onChange={(e)=>setAlternativeTagNo(e.target.value)}  step='1' name="alternativeTagNo" id="regular-form-2" type="number" className="form-control"  placeholder="Alternative Tag No."  />
                                                        </div>
                                                        <div className={` ${IsANNYUser ==1 ? 'hidden' : ' mt-3 position-relative '} `}>
                                                            <label htmlFor="regular-form-1" className="form-label">User Type<font color='red'> *</font></label>
                                                            <select required  value={userType} onChange={(e)=>setUserType(e.target.value)}  name="userType" id="regular-form-2" className="form-control">
                                                                {
                                                                    CompType == 2 ? 
                                                                    (
                                                                        <option value="">Select User Type</option> 
                                                                    ) : '' 

                                                                }
{
                                                                    CompType == 2 ? 
                                                                    (
                                                                        <option value="1">Standard</option>
                                                                    ) : '' 

                                                                }                                                                
                                                                <option value="2">Cleaner</option>                                                               
                                                            </select> 
                                                        </div>  
                                                        <div className={` ${IsANNYUser ==1 ? 'hidden' : ' mt-3 position-relative '} `}>
                                                            <label htmlFor="regular-form-1" className="form-label">User Status<font color='red'> *</font></label>
                                                            <select required  value={active} onChange={(e)=>setActive(e.target.value)}  name="active" id="regular-form-2" className="form-control">
                                                                <option value="">Select Status</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>                                                               
                                                            </select> 
                                                        </div>                                                                                                                
                                                        <div className="mt-3">
                                                            <button type="submit" className="btn btn-primary-dark shadow-md mr-2 float-left">Submit</button>
                                                            { loader ? 
                                                                    (
                                                                        <img className="ml-2 pt-1" src={`${images.loader}`} width={30}/>
                                                                ) : ' '
                                                            }
                                                            

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

}
export default checkAuth(Main,'admin');
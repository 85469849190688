import * as React from "react";
import { useEffect, useState } from 'react';
import images from '../../../Constants/image'
import Sidebar from '../../../layout/Sidebar';
import checkAuth from '../../../middleware/checkAuth'
import {getUerSettings,saveSetting} from '../../../Services/Settings';
import {getData} from '../../../Services/Mood';
import {getDeskHeights} from '../../../Services/Desk';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useLocation, useParams  } from "react-router-dom";
function Main() {
    
    const navigate = useNavigate();
    const params = useParams();
    const {state} = useLocation();

    const [loaderSetting, setLoaderSetting] = useState(false);
    // const [loaderPreference, setLoaderPreference] = useState(false);




    const [sleepTime, setSleepTime] = useState('');
    const [autoLockTime, setAutoLockTime] = useState('');
    const [autoLogoutOnDisconnect, setAutoLogoutOnDisconnect] = useState('');
    
    
    const [isLastSetting, setisLastSetting] = useState('');
    const [standUpReminder, setStandUpReminder] = useState('');
    const [drinkWaterReminder, setDrinkWaterReminder] = useState('');
    
    const [lockKey1, setLockKey1] = useState('');
    const [lockKey2, setLockKey2] = useState('');
    const [businessStartHours, setBusinessStartHours] = useState('');
    const [moodId, setMoodId] = useState('');
    const [positionId, setPositionId] = useState('');
    const [positionList, setPositionList] = useState([]);
    const [moodList, setMoodList] = useState([]);


    
    useEffect(()=>{
        async function data()
        {
            const data =   await getUerSettings();
            // console.log(data.data.data.SleepTime);
            if(data.status == 400){

                toast.error(data.msg);
            }
            else
            {
                setSleepTime(data.data.data.SleepTime);

                setAutoLockTime(data.data.data.AutoLockTime);
                setAutoLogoutOnDisconnect(data.data.data.AutoLogoutOnDisconnect);
                
                setisLastSetting(data.data.data.IsLastSetting.toString());
                setStandUpReminder(data.data.data.StandUpReminder);
                setDrinkWaterReminder(data.data.data.DrinkWaterTimeFreq);
                
                setLockKey1(data.data.data.lockKey1);
                setLockKey2(data.data.data.lockKey2);
                setBusinessStartHours(data.data.data.BusinessStartHours);
                setMoodId(data.data.data.MoodId);
                setPositionId(data.data.data.PositionId);
            }
        }


        async function getPositionData()
        {
            const data = await getDeskHeights();
            setPositionList(data.data);
        }
        async function getMoodData()
        {
            const data = await getData();
            setMoodList(data.data);
        }
        data();
        getMoodData();
        getPositionData();

    },[]);
 
    const handleSettingUpdate = async (e) => {
        setLoaderSetting(true)
        e.preventDefault()
        const userId = localStorage.getItem('id') != null  ? localStorage.getItem('id') :  navigate("/login");
        const data = {UserId:userId,SleepTime:e.target.sleepTime.value,AutoLockTime:e.target.autoLockTime.value,AutoLogoutOnDisconnect:e.target.autoLogoutOnDisconnect.value,IsLastSetting:e.target.isLastSetting.value,DrinkWaterTimeFreq: e.target.drinkWaterReminder.value,StandUpReminder:e.target.standUpReminder.value,lockKey1:e.target.lockKey1.value,lockKey2:e.target.lockKey2.value,BusinessStartHours:e.target.businessStartHours.value,MoodId:e.target.mood.value,PositionId:e.target.position.value};
        const res = await saveSetting(data);
        res.status == 200 ? toast.success(res.msg) : toast.error(res.msg) ;
        setLoaderSetting(false)
    } 
    

    return (
        <>
        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />          
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                            <form onSubmit={handleSettingUpdate} className="w-full">
                                <div className="grid grid-cols-12 gap-6 mt-5">
                                    <div className="intro-y col-span-12 lg:col-span-6">
                                        <div className="intro-y flex items-center h-10">
                                            <h2 className="text-lg font-medium truncate mr-5">
                                             Settings & Preferences
                                            </h2>
                                        </div>


                                        <div className="intro-y box">
                                            <div id="input" className="p-5 table w-full">
                                                <div className="preview">
                                                    
                                                        <div>
                                                            <label htmlFor="regular-form-1" className="form-label">Sleep Time<font color='red'> *</font></label>
                                                            
                                                            <select required  value={sleepTime} onChange={(e)=>setSleepTime(e.target.value)}  name="sleepTime" id="regular-form-2" className="form-control">
                                                                <option value="-1">Never</option>
                                                                <option value="1">1 Mins</option>
                                                                <option value="30">30 Mins</option>
                                                                <option value="45">45 Mins</option>
                                                                <option value="60">60 Mins </option>
                                                                <option value="90">90 Mins</option>                                                                
                                                            </select>                                                              
                                                        </div>
                                                        <div className="mt-3 position-relative">
                                                            <label htmlFor="regular-form-2" className="form-label">Auto Lock Time<font color='red'> * </font></label>
                                                            <select required  value={autoLockTime} onChange={(e)=>setAutoLockTime(e.target.value)}  name="autoLockTime" id="regular-form-2" className="form-control">
                                                                <option value="-1">Never</option>
                                                                <option value="30">30 Mins</option>
                                                                <option value="45">45 Mins</option>
                                                                <option value="60">60 Mins </option>
                                                                <option value="90">90 Mins</option>                                                                
                                                            </select>                                                              
                                                        </div> 
                                                        <div className="mt-3 position-relative">
                                                            <label htmlFor="regular-form-2" className="form-label">Auto Logout On Disconnect<font color='red'> * </font></label>
                                                            <select required  value={autoLogoutOnDisconnect} onChange={(e)=>setAutoLogoutOnDisconnect(e.target.value)}  name="autoLogoutOnDisconnect" id="regular-form-2" className="form-control">
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>                                                              
                                                        </div> 
                                                        <div className=" mt-3  position-relative">
                                                            <label htmlFor="regular-form-1" className="form-label">Stand Up Reminder<font color='red'> *</font></label>
                                                            
                                                            <select required  value={standUpReminder} onChange={(e)=>setStandUpReminder(e.target.value)}  name="standUpReminder" id="regular-form-2" className="form-control">
                                                                <option value="-1">Never</option>
                                                                <option value="1">1 Mins </option>
                                                                <option value="60">60 Mins </option>
                                                                <option value="90">90 Mins</option>                                                                
                                                                <option value="120">120 Mins</option>                                                                
                                                            </select>
                                                        </div>
                                                        <div className=" mt-3  position-relative">
                                                            <label htmlFor="regular-form-1" className="form-label">Drink Water Reminder<font color='red'> *</font></label>
                                                            
                                                            <select required  value={drinkWaterReminder} onChange={(e)=>setDrinkWaterReminder(e.target.value)}  name="drinkWaterReminder" id="regular-form-2" className="form-control">
                                                                <option value="-1">Never</option>
                                                                <option value="1">1 Mins </option>
                                                                <option value="30">30 Mins</option>
                                                                <option value="45">45 Mins</option>
                                                                <option value="60">60 Mins </option>
                                                                <option value="90">90 Mins</option>                                                                
                                                            </select>     
                                                        </div>
                                                        <div className="mt-3 position-relative hidden">
                                                            <label htmlFor="regular-form-2" className="form-label">Lock Key 1<font color='red'> * </font></label> 
                                                            <select required  value={lockKey1} onChange={(e)=>setLockKey1(e.target.value)}  name="lockKey1" id="regular-form-2" className="form-control">
                                                                <option value="0" selected='selected'>Select Option</option>
                                                                <option value="1">Increase</option>
                                                                <option value="2">Decrease</option>
                                                                <option value="3">Ok </option>
                                                                <option value="4">Back</option>                                                                
                                                            </select>                                                                     
                                                        </div>
                                                        <div className="mt-3 position-relative hidden">
                                                            <label htmlFor="regular-form-1" className="form-label">Lock Key 2<font color='red'> *</font></label>
                                                            <select required  value={lockKey2} onChange={(e)=>setLockKey2(e.target.value)}  name="lockKey2" id="regular-form-2" className="form-control">
                                                                <option value="0" selected='selected'>Select Option</option>
                                                                <option value="1" >Increase</option>
                                                                <option value="2">Decrease</option>
                                                                <option value="3">Ok </option>
                                                                <option value="4">Back</option>                                                                
                                                            </select>                                                                                                                               
                                                        </div>
                                                        <div className="mt-3 position-relative hidden">
                                                            <label htmlFor="regular-form-2" className="form-label">Business Start Hours<font color='red'> *</font></label>
                                                            
                                                            <select required  value={businessStartHours} onChange={(e)=>setBusinessStartHours(e.target.value)}  name="businessStartHours" id="regular-form-2" className="form-control">
                                                                <option value="0" selected='selected'>0</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8 </option>
                                                                <option value="9">9</option>                                                                
                                                                <option value="10">10</option>                                                                
                                                                <option value="11">11</option>                                                                
                                                            </select>
                                                        </div>

                                                        <div className="mt-3 position-relative">
                                                            <label htmlFor="regular-form-1" className="form-label">Is Last Setting<font color='red'> *</font></label> 
                                                            <select value={isLastSetting} onChange={(e)=>setisLastSetting(e.target.value)}  name="isLastSetting" id="regular-form-2" className="form-control">
                                                                <option value="true">True</option>
                                                                <option value="false">False</option>
                                                            </select>                                                            
                                                        </div>

                                                        <div className={`${ isLastSetting == 'true' ? 'hide hidden mt-3 position-relative' : 'mt-3 position-relative'   }`}>
                                                            <label htmlFor="regular-form-2" className="form-label">Default Position<font color='red'> *</font></label>
                                                            <select value={positionId}  onChange={(e)=>setPositionId(e.target.value)}  name="position" id="regular-form-2"  className="form-control">


                                                                <option value="">Select Position</option>
                                                                {positionList ? positionList.map((item, index) => (
                                                                <option key={`${index}`}  value={`${item['Id']}`} >{ item['PositionName']}</option>
                                                                )) : " " }
                                                            </select>

                                                        </div>
                                                        <div className={`${ isLastSetting == 'true' ? 'hide hidden mt-3 position-relative' : 'mt-3 position-relative'   }`}>
                                                            <label htmlFor="regular-form-2" className="form-label">Default Mood<font color='red'> *</font></label>
                                                            <select required value={moodId}  onChange={(e)=>setMoodId(e.target.value)}  name="mood" id="regular-form-2"  className="form-control">
                                                                <option value="">Select Position</option>
                                                                {moodList ? moodList.map((item, index) => (
                                                                <option key={`${index}`}  value={`${item['Id']}`}>{ item['MoodName']}</option>
                                                                )) : " " }
                                                            </select>

                                                        </div>
                                                        <div className="mt-3">
                                                            <button type="submit" className="btn btn-primary shadow-md mr-2 float-left">Save</button>
                                                            { loaderSetting ? 
                                                                    (
                                                                        <img className="ml-2 pt-1" src={`${images.loader}`} width={30}/>
                                                                ) : ' '
                                                            }
                                                            

                                                        </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                                                         
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

}
export default checkAuth(Main,'user');

import { useEffect, useState } from 'react';
import * as React from "react";
import images from '../../Constants/image'
import login from '../../Services/Login';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState(localStorage.getItem('username')!=null ? localStorage.getItem('username') : '');
    const [password, setPassword] = useState(localStorage.getItem('password')!=null ? localStorage.getItem('password') : '');
    const [checked, setChecked] = React.useState(localStorage.getItem('checked')!=null ? true : false);
    const [passwordType, setPasswordType] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(faEye);
    
    const togglePassword = ()  =>
    {
            if (passwordType==='password'){
                setPasswordType('text');
                setPasswordIcon(faEyeSlash);
            } else {
                setPasswordType('password');
                setPasswordIcon(faEye);
            }
    }

    useEffect(()=>{
        localStorage.getItem('token') != null  ?  localStorage.getItem('type') == 'admin' ? navigate('/admin') : navigate('/user')  :  navigate("/login");
    },[]);
    const handleLogin = async(e) => {
        e.preventDefault();
        if(checked==true){
            localStorage.setItem('username',username);
            
            localStorage.setItem('password',password);
            localStorage.setItem('checked',checked);
        }
        else
        {
            localStorage.removeItem('username',username);
            localStorage.removeItem('password',password);
            localStorage.removeItem('checked',checked);            
        }
        const res =  await login(username,password);
        res.type != 0  ?  localStorage.getItem('type') == 'admin' ? navigate('/admin') : navigate('/user')  :  navigate("/login"); toast.error(res.msg); ;
    }


    

    return ( 
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />         
            <div className="login">
                <div className="container sm:px-10">
                    <div className="block xl:grid grid-cols-2 gap-4">
                        <div className="hidden xl:flex flex-col min-h-screen">
                            <a href="" className="-intro-x flex items-center pt-5">
                                <img alt="Midone - HTML Admin Template" className="logo__image w-20" src={`${images.logo}`} />
                                {/* <span className="text-white text-lg ml-3"> Automateo </span>  */}
                            </a>
                            <div className="my-auto">
                                <img alt="Midone - HTML Admin Template" className="-intro-x w-1/2 -mt-16" src={`${images.illustration}`} />
                                <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                    A few more clicks to <br /> sign in to your account.
                                </div>
                            </div>
                        </div>
                        <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                            <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                                <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                                    Sign In
                                </h2>
                                <form id='login' onSubmit={handleLogin}>
                                    <div className="intro-x mt-8">
                                        <div className='relative'>

                                            <input type="text" className="intro-x login__input form-control py-3 px-4 block" placeholder="Username" onChange={(e)=> setUsername(e.target.value)} value={username} required/>
                                        </div>
                                        <div className='relative'>
                                            <input type={passwordType} className="intro-x login__input form-control py-3 px-4 block mt-4" placeholder="Password" onChange={(e)=> setPassword(e.target.value)} value={password} required/>
                                            <button type='button' className="top-0 right-0 z-100 absolute btn btn-primary-dark py-4 px-4 w-10 xl:w-10 align-top" onClick={togglePassword}><FontAwesomeIcon icon={passwordIcon}  size="sm" /></button>
                                            
                                        </div>
                                    </div>
                                    <div className="intro-x flex text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4">
                                        <div className="flex items-center mr-auto">
                                            <input id="remember-me" type="checkbox" className="form-check-input border mr-2" defaultChecked={checked} onChange={() => setChecked((state) => !state)}  />
                                            <label className="cursor-pointer select-none" >Remember me</label>
                                        </div>
                                    </div>
                                    <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                                        <button type='submit' className="btn btn-primary-dark py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    );

}
export default Login;
import * as React from "react";
import { useEffect, useState } from 'react';
import Sidebar from '../../../layout/Sidebar';
import checkAuth from '../../../middleware/checkAuth'
import { addData, currentSettings } from '../../../Services/Mood';
import images from '../../../Constants/image'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation, useParams } from "react-router-dom";
function Main() {

    const navigate = useNavigate();
    const params = useParams();
    const { state } = useLocation();

    const [loader, setLoader] = useState(false);
    const [moodname, setMoodName] = useState('');
    const [yellow, setYellow] = useState('');
    const [white, setWhite] = useState('');

    const [submitType, setSubmitType] = useState('0');

    useEffect(() => {
        if (state != null) { setSubmitType(state.data[state.key].Id); setMoodName(state.data[state.key].MoodName); setYellow(state.data[state.key].Yellow); setWhite(state.data[state.key].White) }
    }, [])

    const handleCurrentSettings = async (e) => {
        const data = await currentSettings();
        if (data.status != 400) {

            if (e == 'yellow') {
                setYellow(data.yellow);
            }
            else if (e == 'white') {
                setWhite(data.white);
            }
            else {
                setWhite('');
                setYellow('');
            }
        }
        else {
            toast.error(data.msg)
        }

    }

    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault()
        const userId = localStorage.getItem('id') != null ? localStorage.getItem('id') : navigate("/login");
        const data = { Id: submitType, MoodName: e.target.moodname.value, White: e.target.white.value, Yellow: e.target.yellow.value, UserId: userId };
        const res = await addData(data);
        res.status == 200 ? toast.success(res.msg) : toast.error(res.msg);
        setLoader(false)
        if (submitType == 0 && res.status == 200) {
            setMoodName('');
            setYellow('');
            setWhite('');
        }
    }


    return (
        <>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            <div className="flex overflow-hidden">
                <Sidebar />
                <div className="content">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-9">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12 mt-8">
                                    <div className="intro-y flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            {submitType == 0 ? 'Add' : 'Update'} Mood
                                        </h2>
                                    </div>
                                    <div className="grid grid-cols-12 gap-6 mt-5">
                                        <div className="intro-y col-span-12 lg:col-span-6">


                                            <div className="intro-y box">
                                                <div id="input" className="p-5 table w-full">
                                                    <div className="preview">
                                                        <form onSubmit={handleSubmit}>
                                                            <div>
                                                                <label htmlFor="regular-form-1" className="form-label">Mood Name<font color='red'> *</font></label>
                                                                <input value={moodname} onChange={(e) => setMoodName(e.target.value)} name="moodname" id="regular-form-1" type="text" className="form-control" maxLength="15" placeholder="Mood Name" required />
                                                            </div>
                                                            <div className="mt-3 position-relative">
                                                                <label htmlFor="regular-form-2" className="form-label">White (%)<font color='red'> *</font></label>
                                                                <input value={white} onChange={(e) => setWhite(e.target.value)} step='1' name="white" id="regular-form-2" type="number" className="form-control" minLength='1' maxLength="3" min='0' max='100' placeholder="White (%)" required />
                                                                <button type="button" className="btn btn-primary-dark btn-right" onClick={() => handleCurrentSettings('white')}>Get white</button>
                                                            </div>
                                                            <div className="mt-3 position-relative">
                                                                <label htmlFor="regular-form-2" className="form-label">Yellow (%)<font color='red'> *</font></label>
                                                                <input value={yellow} onChange={(e) => setYellow(e.target.value)} step='1' name="yellow" id="regular-form-2" type="number" className="form-control" minLength='1' maxLength="3" min='0' max='100' placeholder="Yellow (%)" required />
                                                                <button type="button" className="btn btn-primary-dark btn-right" onClick={() => handleCurrentSettings('yellow')}>Get Yellow</button>
                                                            </div>
                                                            <div className="mt-3">
                                                                <button type="submit" className="btn btn-primary-dark shadow-md mr-2 float-left">Save</button>
                                                                {loader ?
                                                                    (
                                                                        <img className="ml-2 pt-1" src={`${images.loader}`} width={30} />
                                                                    ) : ' '
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default checkAuth(Main, 'user');
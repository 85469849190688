import { useEffect, useState } from 'react';
import * as React from "react";
import images from '../../Constants/image'
import login from '../../Services/Login';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
function Quote() {
    const navigate = useNavigate();
    const [username, setUsername] = useState(localStorage.getItem('username')!=null ? localStorage.getItem('username') : '');
    const [password, setPassword] = useState(localStorage.getItem('password')!=null ? localStorage.getItem('password') : '');
    const [checked, setChecked] = React.useState(localStorage.getItem('checked')!=null ? true : false);
    const [passwordType, setPasswordType] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(faEye);
    
    const togglePassword = ()  =>
    {
            if (passwordType==='password'){
                setPasswordType('text');
                setPasswordIcon(faEyeSlash);
            } else {
                setPasswordType('password');
                setPasswordIcon(faEye);
            }
    }

    useEffect(()=>{
        // localStorage.getItem('token') != null  ?  localStorage.getItem('type') == 'admin' ? navigate('/admin') : navigate('/user')  :  navigate("/login");
    },[]);
    const handleLogin = async(e) => {
        e.preventDefault();
        if(checked==true){
            localStorage.setItem('username',username);
            
            localStorage.setItem('password',password);
            localStorage.setItem('checked',checked);
        }
        else
        {
            localStorage.removeItem('username',username);
            localStorage.removeItem('password',password);
            localStorage.removeItem('checked',checked);            
        }
        const res =  await login(username,password);
        // res.type != 0  ?  localStorage.getItem('type') == 'admin' ? navigate('/admin') : navigate('/user')  :  navigate("/login"); toast.error(res.msg); ;
    }


    

    return ( 
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />         
            <div >
                            <a href="" >
                                <img alt="Automateo" className="logo__image w-20" src={`${images.automateologo}`} />
                              </a>                            
             </div>  
             <div>
             <div>
             <b>Where should your smart home be installed? </b>           
                </div>  
                <div>
                    <div>House
                    </div>
                    <div>Apartment
                    </div>
                    <div>Commercial
                    </div>
                    <div>Industrial
                    </div>
                    </div> 
             </div>             
        </>
    );

}
export default Quote;
import { useEffect, useState } from 'react';
import * as React from "react";
import Sidebar from '../../../layout/SidebarAdmin';
import checkAuth from '../../../middleware/checkAuth'
import { get,destroy } from '../../../Services/Admin/User';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../Loader/loader';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
function Main() {
    const [globalLoader, setGlobalLoader] = useState(true);
    const [list, setList] = useState([]);
    const navigate = useNavigate();
    async function data() {
        const data = await get();
        if (data.status == 400) { toast.error(data.msg) } else { 
            setList(data.data);
            setGlobalLoader(false); 
        }
    }
    useEffect(() => {
        data()
        
    }, [globalLoader]);
    const editpage = (id, list, key) => {

        navigate("/admin/user/edit/" + id, { state: { data: list, key: key } });
    }
 
    const [openModal, setOpenModal] = useState(false);
    const deleteModal = () => {
        setOpenModal(true);
    }
    const closeModal =() => {
        setOpenModal(false);
        localStorage.removeItem('delete_id');
    }
    const deleteItem = async () => {
        setOpenModal(false);
        const data =   await destroy();
        console.log(data);
        if( data.status == 400 ) { toast.error(data.msg) } else { toast.success(data.msg) }        
        localStorage.removeItem('delete_id');
        setGlobalLoader(true); 
    }
    // Modal properties
    return (
        <>

            <Loader toggle={globalLoader} />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="flex overflow-hidden">
                <Sidebar />
                <div className="content z-index-0">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-9">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12 mt-8">
                                    <div className="intro-y  items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5 float-left">
                                            Users
                                        </h2>
                                        <NavLink to='/admin/user/add' className="btn btn-primary-dark shadow-md mr-2 float-right">Add User</NavLink>
                                    </div>
                                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                                    </div>
                                    <div className="grid grid-cols-12 gap-6 mt-2">

                                        <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                                            <table className="table table-report -mt-2">
                                                <thead>
                                                    <tr>
                                                        <th className="whitespace-nowrap">Tag No.</th>
                                                        <th className="whitespace-nowrap">User Type</th>
                                                        <th className="whitespace-nowrap">Name</th>
                                                        <th className="whitespace-nowrap">Status</th>
                                                        
                                                        <th className="whitespace-nowrap">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list ? list.map((item, index) => (
                                                        <tr className="intro-x" key={index}>
                                                            <td>{item['TagNo']}</td>
                                                            <td>{item['UserType']==1   ? item['IsANNYUser'] == 1 ?  "ANNY User" : "Standard" : "Cleaner" }</td>
                                                            <td>{item['FName']} {item['LName']}</td>
                                                            <td>{item['Active'] ? (<label className='py-1  px-2 rounded-full text-white  bg-success'>Active</label>) : (<label className='py-1  px-2 rounded-full text-white  bg-danger'>Deative</label>) }</td>
                                                            <td className="table-report__action w-56">
                                                                <div className="flex">
                                                                    <button className='mr-3' onClick={() => editpage(item['UserId'], list[index], index)} key={index} ><FontAwesomeIcon icon={faEdit} size="1x" /> Edit</button>

                                                                        {
                                                                            item['IsANNYUser'] == 0 ? (
                                                                                <a
                                                                                className="flex items-center text-danger" href='#'
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    localStorage.setItem('delete_id', item['UserId']);
                                                                                    deleteModal();
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon className='mr-1' icon={faTrash} size="1x" />  Delete
                                                                            </a>
                                                                            ) : ''
                                                                        }

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : " "}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

<Modal
        isOpen={openModal}
        // style={customStyles}
        className='modalC'
        contentLabel="Example Modal"
      >
        
        <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Delete Desk</h1>
        </div>
        <div className="modal-body text-center">
          <p>Are you sure want to delete?</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary mr-5 " onClick={closeModal}>Cancel</button>
          <button type="button" className="btn btn-primary-dark" onClick={deleteItem}> Yes</button>
        </div>
      </div>

                                                                         
        
        
        
        
      </Modal>


        </>
    );

}
export default checkAuth(Main, 'admin');
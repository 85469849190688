import React, {Component} from 'react'
import { Navigate } from "react-router-dom";

const checkAuth = (WrappedComponent,type) => {
    // console.log(type);

class CheckAuthComponent extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            isLogin : false,
            type : '',
        }
    }

    componentDidMount()
    {
        const res = localStorage.getItem('token') ? true : false ;
        const type = localStorage.getItem('type') ;
        
        this.setState({
            isLogin:res,
            type:type, // user type 
        });

    }
    componentWillMount()
    {
        const res = localStorage.getItem('token') ? true : false ;
        const type = localStorage.getItem('type') ;
        
        this.setState({
            isLogin:res,
            type:type, // user type 
        });


    }    

      render() {
        if(this.state.isLogin)
        {
            if(this.state.type === type)
            {
                return <WrappedComponent  { ...this.props}/>;
            }
            else
            {
                return   <Navigate to="/login" replace={true} />                
            }
        }
        else
        {
            return   <Navigate to="/login" replace={true} />
        }
      }
    }
   
    
    return CheckAuthComponent;

}



export default checkAuth;
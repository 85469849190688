import * as React from "react";
import { useEffect, useState } from 'react';
import images from '../../../Constants/image'
import Sidebar from '../../../layout/SidebarAdmin';
import checkAuth from '../../../middleware/checkAuth'
import { getVersion, updateVersion } from '../../../Services/Admin/Setting';
import Loader from '../../Loader/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Main() {
    
    const [firmwareVersion, setFirmwareVersion] = useState('');
    const [file, setFile] = useState()

    function handleChange(event) {
        setFile(event.target.files[0])
      }

    const [loader] = useState(false);
    
    const [globalLoader, setGlobalLoader] = useState(true);
    async function getFVersion() 
    {
        
        const data = await getVersion();
        if (data.status == 400) { toast.error(data.msg) } else { setFirmwareVersion(data.data.ControllerFirmwareVersion);setGlobalLoader(false);  }
        
    }
    useEffect( ()=>{
        getFVersion();
    },[globalLoader])
 

    async function handleSubmit(event) {
        event.preventDefault()
        const data = await updateVersion(file, firmwareVersion);
        if (data.status == 400) {  toast.error(data.msg)  } else { toast.success(data.data.message) }        
      }

    return (
        <>
        <Loader toggle={globalLoader} />
        <ToastContainer  position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />          
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                                <div className="intro-y flex items-center h-10">
                                    <h2 className="text-lg font-medium truncate mr-5">
                                    Firmware Version
                                    </h2>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-5">
                                    <div className="intro-y col-span-12 lg:col-span-6">


                                        <div className="intro-y box">
                                            <div id="input" className="p-5 table w-full">
                                                <div className="preview">
                                                    <form onSubmit={handleSubmit}>
                                                        <div>
                                                            <label htmlFor="regular-form-1" className="form-label"> Firmware Version <font color='red'> *</font></label>
                                                            <input value={firmwareVersion}  onChange={(e)=>setFirmwareVersion(e.target.value)}  name="alternativeTagNo" id="regular-form-1" className="form-control"  placeholder="Firmware Version Number"  />
                                                        </div>   
                                                            <br></br>
                                                        <div>
                                                            <label htmlFor="regular-form-1" className="form-label"> Upload BIN File <font color='red'> *</font></label>
                                                            <input type="file" onChange={handleChange}/>
                                                        </div>

                                                        <div className="mt-3">
                                                            <button type="submit" className="btn btn-primary-dark shadow-md mr-2 float-left">Submit</button>
                                                            { loader ? 
                                                                    (
                                                                        <img className="ml-2 pt-1" src={`${images.loader}`} width={30}/>
                                                                ) : ' '
                                                            }
                                                            

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

}
export default checkAuth(Main,'admin');
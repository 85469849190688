import { useEffect, useState } from 'react';
import * as React from "react";
import Sidebar from '../../../layout/Sidebar';
import checkAuth from '../../../middleware/checkAuth'
import { getDeskHeights,deleteDesk } from '../../../Services/Desk';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { mmToCm } from '../../../Helpers/helper';
import Modal from 'react-modal';
function Main() {

    const [deskList, setDeskList] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        async function data() {
            const data = await getDeskHeights();
            if (data.status == 400) { toast.error(data.msg) } else { setDeskList(data.data); }

        }
        data()
    }, [deskList]);
    const editpage = (id, deskList, key) => {

        navigate("/user/desk/edit/" + id, { state: { data: deskList, key: key } });
    }

    // Modal properties
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex:99,
        },
      };
    const [openModal, setOpenModal] = React.useState(false);
    const deleteModal = () => {
        setOpenModal(true);
    }
    const closeModal =() => {
        setOpenModal(false);
        localStorage.removeItem('delete_id');
    }
    const deleteItem = async () => {
        setOpenModal(false);
        const data =   await deleteDesk();
        if( data.status == 400 ) { toast.error(data.msg) } else { toast.success(data.msg) }        
        localStorage.removeItem('delete_id');
    }
    // Modal properties
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="flex overflow-hidden">
                <Sidebar />
                <div className="content z-index-0">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-9">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12 mt-8">
                                    <div className="intro-y  items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5 float-left">
                                            Desk Heights
                                        </h2>
                                        <NavLink to='/user/desk/add' className="btn btn-primary-dark shadow-md mr-2 float-right">Add Desk Height</NavLink>
                                    </div>
                                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                                    </div>
                                    <div className="grid grid-cols-12 gap-6 mt-2">

                                        <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                                            <table className="table table-report -mt-2">
                                                <thead>
                                                    <tr>
                                                        <th className="whitespace-nowrap">Position Name </th>
                                                        <th className="whitespace-nowrap">Height (cm)</th>
                                                        <th className="whitespace-nowrap">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {deskList ? deskList.map((item, index) => (
                                                        <tr className="intro-x" key={index}>
                                                            <td>{item['PositionName']}</td>
                                                            <td>{mmToCm(item['Height'])}</td>
                                                            <td className="table-report__action w-56">
                                                                <div className="flex">
                                                                    <button className='mr-3' onClick={() => editpage(item['Id'], deskList, index)} key={index} ><FontAwesomeIcon icon={faEdit} size="1x" /> Edit</button>
                                                                    <a
                                                                        className="flex items-center text-danger" href='#'
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            localStorage.setItem('delete_id', item['Id']);
                                                                            deleteModal();
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon className='mr-1' icon={faTrash} size="1x" />  Delete
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : " "}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

<Modal
        isOpen={openModal}
        // style={customStyles}
        className='modalC'
        contentLabel="Example Modal"
      >
        
        <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Delete Desk</h1>
        </div>
        <div class="modal-body text-center">
          <p>Are you sure want to delete?</p>
        </div>
        <div class="modal-footer">save
          <button type="button" class="btn btn-secondary mr-5 " onClick={closeModal}>Close</button>
          <button type="button" class="btn btn-primary-dark" onClick={deleteItem }> Yes changes</button>
        </div>
      </div>

                                                                         
        
        
        
        
      </Modal>


        </>
    );

}
export default checkAuth(Main, 'user');

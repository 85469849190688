// src/components/Summary.js
import React, { useContext } from 'react';
import DataContext from '../../context/DataContext';

const Summary = () => {
  const { buildingType, constructionStage, floors, floorDetails, totalPrice } = useContext(DataContext);

  return (
    <div>
      <h1>Summary</h1>
      <p>Building Type: {buildingType}</p>
      <p>Construction Stage: {constructionStage}</p>
      <p>Number of Floors: {floors}</p>
      <p>Total Price: {totalPrice}</p>

      {floorDetails.map((floor, index) => (
        <div key={index}>
          <h2>Floor {index + 1}</h2>
          <p>Central AC: {floor.centralAC ? 'Yes' : 'No'}</p>
          <p>Central Water Heating: {floor.centralWaterHeating ? 'Yes' : 'No'}</p>
          {/* List floor details */}
        </div>
      ))}
    </div>
  );
};

export default Summary;

const message = {
    invalidUserPass : "Invalid Username/Password.",
    serverError : "Internal server error. Plese contact to the support team.",
    deskInsert : "Desk height has been successfully saved.",
    deskUpdated : "Desk height has been successfully updated.",
    deskDeleted : "Desk height has been successfully deleted.",
    moodInsert : "Mood has been successfully saved.",
    moodUpdated : "Mood has been successfully updated.",    
    moodDeleted : "Mood has been successfully deleted.",    
    userSettingUpdated : "User Settings/Preferences has been successfully updated.",    
    userPrefrenceUpdated : "User Preference has been successfully updated.",    
    lockKeySameError : "Lock key 1 & key 2 should be diffrent.",    
    userInsert : "User has been successfully Created.",    
    userUpdated : "User has been successfully Updated.",
    userDeleted : "User has been successfully Deleted.",
    userNotExist : "User not exist.",
    duplicateUserError : "User name or tag already exists!",

    deskAdminInsert : "Desk has been successfully saved.",
    deskAdminUpdated : "Desk has been successfully updated.",
    deskAdminDeleted : "Desk has been successfully deleted.",
    deskAdminReset : "Desk has been successfully reset.",

}

export default message;
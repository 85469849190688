export const generateInvoiceNumber = () => {
    return Math.floor(100000 + Math.random() * 900000); // Generates a 6-digit random number
  };

export const getCurrentDateTime = () => {
    const now = new Date();
    
    const padTo2Digits = (num) => {
      return num.toString().padStart(2, '0');
    };
  
    const day = padTo2Digits(now.getDate());
    const month = padTo2Digits(now.getMonth() + 1); // Months are zero-indexed
    const year = now.getFullYear().toString().slice(-2); // Get last 2 digits of year
  
    const hours = padTo2Digits(now.getHours());
    const minutes = padTo2Digits(now.getMinutes());
    const seconds = padTo2Digits(now.getSeconds());
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };


  export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 2, // Set precision
      minimumFractionDigits: 2  // Ensure two decimal places
    }).format(amount);
  };

  const customOrder = [1, 2, 3, 4]; // Define the specific order for ItemTypeId
  export const comparator = (a, b) => {
    return customOrder.indexOf(a.ItemTypeId) - customOrder.indexOf(b.ItemTypeId);
  };

  // Helper function to generate a random number for the Id
  export const generateId = () => Math.floor(Math.random() * 100000);

  export const styles = {
    pdfbuttons: {
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    summaryitemhead: {
      background:'darkgray'
    },
    summaryitemtotal:{
     background:'#7d7a7a'
   },
    trLeftAlign: {
     textAlign:'left'
   },
   trRightAlign: {
     textAlign:'right'
   },
   trCenterAlign: {
     textAlign:'center'
   },
  };

  export const areaItemCodeOrder = ['bedr', 'batr', 'drer', 'drar', 'lobb', 'dini', 'kitc', 'balc', 'pass', 'stai', 'elev'];

  export const getItemCodeOrder = (itemCode) => {
    const index = areaItemCodeOrder.indexOf(itemCode);
    return index !== -1 ? index : areaItemCodeOrder.length; // Items not in the array are placed at the end
  };


  export const constructionStages = {
    'ytst': 'Yet to Start',
    'inpr': 'In Progress',
    'alco': 'Renovating',
    'alre': 'Already Residing'
  };
 
  
import * as React from "react";
import axios from "axios";
import URL from '../../Constants/links';
import message from '../../Constants/message'
const get = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.desks)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        
        return data;
      } catch (error) {
          return {status:400,msg:message.serverError};
      }    
}

const add = async (json) =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.addDesk,json)
        .then((res)=>
          {
            const msg = json.Id ==0 ?  message.deskAdminInsert : message.deskAdminUpdated;
            return {data:res,msg:msg,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


const destroy = async () =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.deleteDesk,{Id:localStorage.getItem('delete_id')})
        .then((res)=>
          {
            
            return {data:res,msg:message.deskAdminDeleted,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const resetDesk = async (id) =>
{
    try {
        const data = await axios.post(URL.api_url+URL.deskReset,{DeskId:id})
        .then((res)=>
          {
            
            return {data:res,msg:res.Msg,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


 

export  {get,add,destroy, resetDesk};
import React, { useContext , useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import images from '../../Constants/image'
import BuildingType from './BuildingType';
import ConstructionStage from './ConstructionStage';
import AddSpaces from './AddSpaces';
import ContactForm from './ContactForm'
import '../../App.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


const GenerateQuote = () => {
  const {currentComponent, setCurrentComponent, direction} = useContext(DataContext);

  const renderComponent = () => {
    switch (currentComponent) {
      case 'buildingtype':
        return <BuildingType />;
      case 'constructionstage':
        return <ConstructionStage />;
      case 'addspaces':
        return <AddSpaces />;
      case 'contactform':
        return <ContactForm />;
      default:
        return <BuildingType />;
    }
  };

 

  return (
     <>
    <TransitionGroup className="main-container">
      <CSSTransition
        key={currentComponent}
        timeout={1000}
        classNames={direction}
      >
      {renderComponent()}
    </CSSTransition>
  </TransitionGroup>
     {/* <div class="container" >      
      
       <div class="text-wrapper-4" onClick={() => setCurrentComponent('buildingtype')}>BuildingType</div>
       <div class="text-wrapper-4" onClick={() => setCurrentComponent('constructionstage')}>ConstructionStage</div>
       <div class="text-wrapper-4" onClick={() => setCurrentComponent('addspaces')}>AddSpaces</div>
     </div> */}
     </>
  );
};

export default GenerateQuote;

import * as React from "react";
import {NavLink, useNavigate} from 'react-router-dom'
import Sidebar from '../../../layout/Sidebar';
import checkAuth from '../../../middleware/checkAuth'


function Main() {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('type');
        navigate("/login");
    }
    return (
        <>
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                                <div className="intro-y flex items-center h-10">
                                    <h2 className="text-lg font-medium truncate mr-5">
                                        Dashboard
                                    </h2>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-5">
                                    <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                        <NavLink to='/user/desks/'>
                                        <div className="report-box zoom-in">
                                            <div className="box p-5">
                                                <div className="flex">
                                                    {/* <i data-lucide="shopping-cart" className="report-box__icon text-primary"></i> 
                                                    <div className="ml-auto">
                                                        <div className="report-box__indicator bg-success tooltip cursor-pointer" title="33% Higher than last month"> 33% <i data-lucide="chevron-up" className="w-4 h-4 ml-0.5"></i> </div>
                                                    </div> */}
                                                </div>
                                                <div className="text-3xl font-medium leading-8 mt-6">Heights/Positi..</div>
                                                <div className="text-base text-slate-500 mt-1">View</div>
                                            </div>
                                        </div>
                                        </NavLink>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                        <NavLink to='/user/moods/'>
                                        <div className="report-box zoom-in">
                                            <div className="box p-5">
                                                {/* <div className="flex">
                                                    <i data-lucide="credit-card" className="report-box__icon text-pending"></i> 
                                                    <div className="ml-auto">
                                                        <div className="report-box__indicator bg-danger tooltip cursor-pointer" title="2% Lower than last month"> 2% <i data-lucide="chevron-down" className="w-4 h-4 ml-0.5"></i> </div>
                                                    </div>
                                                </div> */}
                                                <div className="text-3xl font-medium leading-8 mt-6">Moods</div>
                                                <div className="text-base text-slate-500 mt-1">View</div>
                                            </div>
                                        </div>
                                        </NavLink>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                                        <NavLink to='user/settings'>
                                        <div className="report-box zoom-in">
                                            <div className="box p-5">
                                                {/* <div className="flex">
                                                    <i data-lucide="monitor" className="report-box__icon text-warning"></i> 
                                                    <div className="ml-auto">
                                                        <div className="report-box__indicator bg-success tooltip cursor-pointer" title="12% Higher than last month"> 12% <i data-lucide="chevron-up" className="w-4 h-4 ml-0.5"></i> </div>
                                                    </div>
                                                </div> */}
                                                <div className="text-3xl font-medium leading-8 mt-6">Settings</div>
                                                <div className="text-base text-slate-500 mt-1">View</div>
                                            </div>
                                        </div>
                                        </NavLink>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y" onClick={handleLogout}>
                                        <div className="report-box zoom-in">
                                            <div className="box p-5">
                                                {/* <div className="flex">
                                                    <i data-lucide="user" className="report-box__icon text-success"></i> 
                                                    <div className="ml-auto">
                                                        <div className="report-box__indicator bg-success tooltip cursor-pointer" title="22% Higher than last month"> 22% <i data-lucide="chevron-up" className="w-4 h-4 ml-0.5"></i> </div>
                                                    </div>
                                                </div> */}
                                                <div className="text-3xl font-medium leading-8 mt-6">Logout</div>
                                                <div className="text-base text-slate-500 mt-1">Click Here</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

}

export default checkAuth(Main,'user');
import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';

import App from './App';
import Router from './routes/index';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { DataProvider } from './context/DataContext';
//import { DataProvider } from './context/DataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <BrowserRouter>
  //     <Router />
  //   </BrowserRouter>
  // </React.StrictMode>

<BrowserRouter basename={''}>
  <DataProvider>
   <Router />
  </DataProvider>
</BrowserRouter>

);
 
reportWebVitals();

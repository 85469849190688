const URL = {
    //api_url : 'http://sanjeev-elkraft.pagekite.me/',
    //api_url : 'http://192.168.29.23:7897/',
   // api_url : 'http://127.0.0.1:7897/',
    //api_url:`${window.location.hostname}:7897/`,
    //api_url : 'http://192.168.29.182:7897/', //local ip and port
    //api_url : 'https://182.77.62.50:7897/', //public ip and port
   // api_url : 'http://82.165.133.111:7897/', //public ip and port http://calc.automateo.de/
    api_url : 'https://calc.automateo.de/node2/api/code/', //for the production api
    login_url : 'auth/',
    deskHeights:"DeskHeights/",
    addDeskHeights:"SaveHeight/",
    currentSettings:"CurrentSetting/",
    userSettings:"Setting/",
    moods:"Moods/",
    addMoods:"SaveMood/",
    saveSetting:"SaveSetting/",
    deskDelete:"DeleteHeight/",
    moodDelete:"DeleteMood/",
    updateFirmware:"UpdateFirmware/",
    
    // Admin API
    addUser:"SaveUser/",
    users:"Users/",
    deleteUser:"DeleteUser/",

    settings:"Settings/",
    addDesk:"SaveDesk/",

    desks:"Desks/",
    deleteDesk:"DeleteDesk/", 
    deskReset:"DeskReset/",
    upload:"Upload/",   
    getFirmwareVersion:"GetFirmwareVersion/",

    items:"Items/",
    miscellaneousitems:"MiscellaneousItems/",
    allitemswithbrandandprice:"AllItemsWithBrandAndPrice/",
    itemdefaultconfiguration:"ItemDefaultConfiguration/",
    areaitemsdefaultconfiguration:"AreaItemsDefaultConfiguration/",

    brands:"Brands/",
    sendemail:"send-email/",
    areacontrollingitems:"AreaControllingItems/"

}

export default URL;

import * as React from "react";
import axios from "axios";
import URL from '../Constants/links';
import message from '../Constants/message'
const login = async (username, password) => {
  try {
    const data = await axios.post(URL.api_url + URL.login_url,
      {
        UserName: username,
        Password: password
      }
      ,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Content-Type,Authorization',
        },

      }

    )
      .then((data) => {
        if (data.status == 200) {
          localStorage.setItem('name', username);
          localStorage.setItem('token', data.data.Token);
          localStorage.setItem('id', data.data.UserId);
          localStorage.setItem('type', data.data.UserType == 3 ? 'admin' : 'user');
          localStorage.setItem('comptype',data.data.CompType);
          
          

          return { type: data.data.UserType == 3 ? 'admin' : 'user' };
        }
        else {
          return { type: 0, msg: message.invalidUserPass};
        }
      }
      )
      .catch((error) => {
        return { type: 0, msg: message.invalidUserPass};
      }
      );
    return data;
  } catch (error) {
    return { type: 0, msg: message.serverError };
  }
}


export default login;
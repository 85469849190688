import * as React from "react";
import axios from "axios";
import URL from '../../Constants/links';
import message from '../../Constants/message'
const get = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.brands)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

export  {get};
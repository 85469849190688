import { createContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [direction, setDirection] = useState('next');
  const [currentComponent, setCurrentComponent] = useState('buildingtype');
  const [buildingType, setBuildingType] = useState('hous');
  const [buildingTypeName, setBuildingTypeName] = useState('House');
  const [constructionStage, setConstructionStage] = useState('ytst');
  const [constructionStageName, setConstructionStageName] = useState('Yet to Start');
  const [floors, setFloors] = useState(1);
  const [renovation, setRenovation] = useState(false);
  const [floorDetails, setFloorDetails] = useState([]);
  const [centralAC, setCentralAC] = useState(false);
  const [centralWaterHeating, setCentralWaterHeating] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
 // const [userDetails, setUserDetails] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('wohn');
  const [selectedBrandName, setSelectedBrandName] = useState('Wohnux');
  const [blobpdf, setBlobpdf] = useState(null);
  const [areaControllingItems, setAreaControllingItems] = useState([]);
  const [totalNumberOfRooms, setTotalNumberOfRooms] = useState(3);
  const [essentialItem, setEssentialItem] = useState([]);
  const [installationCommissioningItem, setInstallationCommissioningItem] = useState([]);
  const [floorTrasnDetails, setFloorTrasnDetails] = useState([]);
  const [userDetails, setUserDetails] = useState({
    to: '',
    attachment: null,
    firstName:'',
    lastName:'',
    company:'',
    city:'',
    email:'',
    mobile:''
  });

  return (
    <DataContext.Provider value={{
      direction, setDirection,
      currentComponent, setCurrentComponent,
      buildingType, setBuildingType,
      buildingTypeName, setBuildingTypeName,
      constructionStage, setConstructionStage,
      constructionStageName, setConstructionStageName,
      floors, setFloors,
      renovation, setRenovation,
      floorDetails, setFloorDetails,
      centralAC, setCentralAC,
      centralWaterHeating, setCentralWaterHeating,
      totalPrice, setTotalPrice,
      userDetails, setUserDetails,
      selectedItems, setSelectedItems,
      selectedBrand, setSelectedBrand,
      selectedBrandName, setSelectedBrandName,
      blobpdf, setBlobpdf,
      areaControllingItems, setAreaControllingItems,
      totalNumberOfRooms, setTotalNumberOfRooms,
      essentialItem, setEssentialItem,
      installationCommissioningItem, setInstallationCommissioningItem,
      floorTrasnDetails, setFloorTrasnDetails
    }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;

import * as React from "react";
import { useEffect, useState } from 'react';
import images from '../../../Constants/image'
import Sidebar from '../../../layout/Sidebar';
import checkAuth from '../../../middleware/checkAuth'
import {addDeskHeights,currentSettings} from '../../../Services/Desk';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useLocation, useParams  } from "react-router-dom";
import message from '../../../Constants/message'
import {mmToCm,cmToMm} from '../../../Helpers/helper';
function Main() {
    
    const navigate = useNavigate();
    const params = useParams();
    const {state} = useLocation();

    const [loader, setLoader] = useState(false);
    const [position, setPosition] = useState('');
    const [height, setHeight] = useState('');
    const [submitType, setSubmitType] = useState('0');
    useEffect(()=>{
        if(state!=null) { setSubmitType(state.data[state.key].Id); setPosition(state.data[state.key].PositionName); setHeight(mmToCm(state.data[state.key].Height)) } 
    },[])

    const handleCurrentSettings = async (e)=> {
        const data = await currentSettings();   
            if( data.status == 400 ) { toast.error(data.msg) } else { setHeight(mmToCm(data.data.data[0].Lastheight)); }
        
    }

    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault()
        const userId = localStorage.getItem('id') != null  ? localStorage.getItem('id') :  navigate("/login");
        const data = {Id:submitType,PositionName:e.target.positions.value,Height:cmToMm(e.target.height.value),UserId:userId};
        const res = await addDeskHeights(data);
        res.status == 200 ? toast.success(res.msg) : toast.error(res.msg) ;
        setLoader(false); 
        if (submitType == 0 && res.status == 200) { setPosition(''); setHeight(''); }
    }


    return (
        <>
        <ToastContainer  position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />          
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                                <div className="intro-y flex items-center h-10">
                                    <h2 className="text-lg font-medium truncate mr-5">
                                    {submitType==0?'Add':'Update'} Desk Height
                                    </h2>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-5">
                                    <div className="intro-y col-span-12 lg:col-span-6">


                                        <div className="intro-y box">
                                            <div id="input" className="p-5 table w-full">
                                                <div className="preview">
                                                    <form onSubmit={handleSubmit}>
                                                        <div>
                                                            <label htmlFor="regular-form-1" className="form-label">Position Name<font color='red'> *</font></label>
                                                            <input value={position}  onChange={(e)=>setPosition(e.target.value)} name="positions" id="regular-form-1" type="text" className="form-control" maxLength="15"  placeholder="Position Name"  required />
                                                        </div>
                                                        <div className="mt-3 position-relative">
                                                            <label htmlFor="regular-form-2" className="form-label">Height (cm) <font color='red'> *   (value between: 62 cm - 127 cm)</font> </label>
                                                            <input value={height}  onChange={(e)=>setHeight(e.target.value)} step='1' name="height" id="regular-form-2" type="number" className="form-control" minLength='3' maxLength="4" min="62" max="127" pattern="" placeholder="Height (cm)"  required/>
                                                            <button type="button" className="btn btn-success btn-right" onClick={handleCurrentSettings}>Get Height</button>
                                                            
                                                        </div>
                                                        <div className="mt-3">
                                                            <button type="submit" className="btn btn-primary shadow-md mr-2 float-left">Save</button>
                                                            { loader ? 
                                                                    (
                                                                        <img className="ml-2 pt-1" src={`${images.loader}`} width={30}/>
                                                                ) : ' '
                                                            }
                                                            

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

}
export default checkAuth(Main,'user');

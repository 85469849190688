import * as React from "react";
import images from '../../Constants/image'
import {NavLink,useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDashboard,faFaceSmile, faArrowRight, faGear,faRuler,faRightFromBracket, faUserAlt, faKey, faGears } from '@fortawesome/free-solid-svg-icons'
function Main() {
    
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('type');
        localStorage.removeItem('desk_id');
        localStorage.removeItem('name');
        localStorage.removeItem('comptype');
        
        navigate("/login");
    }

    return (
        <>
        <div className="top-bar-boxed h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700">
            <div className="h-full flex items-center">
                <a href="" className="logo -intro-x hidden md:flex xl:w-[180px] block">
                    {/* <img alt="Midone - HTML Admin Template" className="logo__image w-6" src={`${images.logo}`} /> */}
                    <span className="logo__text text-white text-lg ml-3"> Automateo </span> 
                </a>
                <nav aria-label="breadcrumb" className="-intro-x h-[45px] mr-auto">
                    <ol className="breadcrumb breadcrumb-light">
                        <li className="breadcrumb-item">
                            &nbsp;
                            {/* Breadcrumb Component */}
                        </li>
                    </ol>
                </nav>
                 <div className="z-50">

                <ul className="inline-flex header-right-data ">
                        <li>
                        <FontAwesomeIcon icon={faUserAlt}  size="lg" /> Welcome: {localStorage.getItem('name')}
                        </li>
                        <li onClick={handleLogout} className="cursor-pointer">
                        <FontAwesomeIcon icon={faRightFromBracket}  size="lg" /> Logout
                        </li>
                        
                    </ul>                    
                 </div>
                <div className="intro-x dropdown hidden">
                    <div className="drop-down-custom dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110" role="button" aria-expanded="false" data-tw-toggle="dropdown">
                        <FontAwesomeIcon icon={faKey}  size="lg" />
                    </div>
                    <div className="dropdown-menu w-56">
                        <ul className="dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
                            <li>
                                <a href="" className="dropdown-item hover:bg-white/5"> <i data-lucide="lock" className="w-4 h-4 mr-2"></i> Change Password </a>
                            </li> 
                             
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="flex overflow-hidden"> 

            <nav className="side-nav">
                <ul>
                    <li> 
                        <NavLink   end to='/admin/user' className={({ isActive, isPending }) => isPending ? "pending side-menu " : isActive ? "side-menu--active side-menu " : "  side-menu" }>
                                <FontAwesomeIcon icon={faUserAlt}  size="lg" />
                                <div className="side-menu__icon"> <i data-lucide="home"></i> </div>
                                <div className="side-menu__title">
                                        Users
                                    <div className="side-menu__sub-icon transform rotate-180"> <i data-lucide="chevron-down"></i> </div>
                                </div> 

                            
                        </NavLink> 
                    </li>   
                    <li> 
                        <NavLink   end to='/admin/desk' className={({ isActive, isPending }) => isPending ? "pending side-menu " : isActive ? "side-menu--active side-menu " : "  side-menu" }>
                                <FontAwesomeIcon icon={faRuler}  size="lg" />
                                <div className="side-menu__icon"> <i data-lucide="home"></i> </div>
                                <div className="side-menu__title">
                                    Desks
                                    <div className="side-menu__sub-icon transform rotate-180"> <i data-lucide="chevron-down"></i> </div>
                                </div> 

                            
                        </NavLink> 
                    </li>
                    <li> 
                        <NavLink   end to='/admin/setting' className={({ isActive, isPending }) => isPending ? "pending side-menu " : isActive ? "side-menu--active side-menu " : "  side-menu" }>
                                <FontAwesomeIcon icon={faGears}  size="lg" />
                                <div className="side-menu__icon"> <i data-lucide="home"></i> </div>
                                <div className="side-menu__title">
                                    Settings
                                    <div className="side-menu__sub-icon transform rotate-180"> <i data-lucide="chevron-down"></i> </div>
                                </div> 

                            
                        </NavLink> 
                    </li>   

                     <li> 
                        <NavLink   end to='/admin/firmware' className={({ isActive, isPending }) => isPending ? "pending side-menu " : isActive ? "side-menu--active side-menu " : "  side-menu" }>
                                <FontAwesomeIcon icon={faGears}  size="lg" />
                                <div className="side-menu__icon"> <i data-lucide="home"></i> </div>
                                <div className="side-menu__title">
                                    Update Firmware Version
                                    <div className="side-menu__sub-icon transform rotate-180"> <i data-lucide="chevron-down"></i> </div>
                                </div> 

                            
                        </NavLink> 
                    </li>                    
                                  
                </ul>
            </nav>
        </div>

        </>
    );

}
export default Main;
import * as React from "react";
import axios from "axios";
import URL from '../Constants/links';
import message from '../Constants/message'
const getData = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.moods+localStorage.getItem('id'))
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


const addData = async (json) =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.addMoods,json)
        .then((res)=>
          {
            const msg = json.Id ==0 ?  message.moodInsert : message.moodUpdated;
            return {data:res,msg:msg,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


const deleteMood = async () =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.moodDelete,{Id:localStorage.getItem('delete_id')})
        .then((res)=>
          {
            
            return {data:res,msg:message.moodDeleted,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const currentSettings = async () =>{
  
  try {
    const data = await axios.get(URL.api_url+URL.currentSettings+localStorage.getItem('id'))
    .then((res)=>
      {
        return {white: res.data[0].LastWhite,yellow :res.data[0].LastYellow};
      }
    )
    .catch((error)=>
      {
        return {status:400,msg:message.serverError};
      }
    );
    return data;
  } catch (error) {
    return {status:400,msg:message.serverError};
  } 
   
}

export  {getData,addData,currentSettings,deleteMood};
import * as React from "react";
import axios from "axios";
import URL from '../../Constants/links';
import message from '../../Constants/message'
const get = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.settings)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const getVersion = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.getFirmwareVersion)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const updateVersion = (file,firmwareVersion ) =>
{
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('VersionNumber', firmwareVersion);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        const data = axios.post(URL.api_url+URL.upload, formData, config)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


const create = async (json) =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.settings,json)
        .then((res)=>
          {
            const msg = json.UserId == 0 ?  message.userInsert : message.userUpdated;
            return {data:res,msg:msg,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

 

export  {get,create, getVersion, updateVersion};
import * as React from "react";
import axios from "axios";
import URL from '../../Constants/links';
import message from '../../Constants/message'

const get = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.items)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const getMiscellaneousItems = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.miscellaneousitems)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const getAllItemsWithBrandAndPrice = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.allitemswithbrandandprice)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const getItemDefaultConfiguration = async (floorCount,buildingType) =>
  {
      try {
          const data = await axios.get(URL.api_url+URL.itemdefaultconfiguration+"?FloorCount="+floorCount+"&BuildingType="+buildingType)
          .then((data)=>
            {
              return {data:data.data};
            }
          )
          .catch((error)=>
            {
              return {status:400,msg:message.serverError};
            }
          );
          return data;
        } catch (error) {
          return {status:400,msg:message.serverError};
        }    
  }

  const getAreaItemsDefaultConfiguration = async (floorCount) =>
    {
        try {
            const data = await axios.get(URL.api_url+URL.areaitemsdefaultconfiguration)
            .then((data)=>
              {
                return {data:data.data};
              }
            )
            .catch((error)=>
              {
                return {status:400,msg:message.serverError};
              }
            );
            return data;
          } catch (error) {
            return {status:400,msg:message.serverError};
          }    
    }


    const getAreaControllingItems = async (floorCount) =>
      {
          try {
              const data = await axios.get(URL.api_url+URL.areacontrollingitems)
              .then((data)=>
                {
                  return {data:data.data};
                }
              )
              .catch((error)=>
                {
                  return {status:400,msg:message.serverError};
                }
              );
              return data;
            } catch (error) {
              return {status:400,msg:message.serverError};
            }    
      }

export  {get, getMiscellaneousItems, getAllItemsWithBrandAndPrice, 
  getItemDefaultConfiguration, getAreaItemsDefaultConfiguration, getAreaControllingItems};
import * as React from "react";
import axios from "axios";
import URL from '../Constants/links';
import message from '../Constants/message'
const getDeskHeights = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.deskHeights+localStorage.getItem('id'))
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        
        return data;
      } catch (error) {
          return {status:400,msg:message.serverError};
      }    
}

const addDeskHeights = async (json) =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.addDeskHeights,json)
        .then((res)=>
          {
            const msg = json.Id ==0 ?  message.deskInsert : message.deskUpdated;
            return {data:res,msg:msg,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


const deleteDesk = async () =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.deskDelete,{Id:localStorage.getItem('delete_id')})
        .then((res)=>
          {
            
            return {data:res,msg:message.deskDeleted,status:200};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}

const currentSettings = async () =>{
  
  try {
    const data = await axios.get(URL.api_url+URL.currentSettings+localStorage.getItem('id'))
    .then((res)=>
      {
        return {data:res};
      }
    )
    .catch((error)=>
      {
        return {status:400,msg:message.serverError};
      }
    );
    return data
  } catch (error) {
    return {status:400,msg:message.serverError};
  }  
   
}

export  {getDeskHeights,addDeskHeights,currentSettings,deleteDesk};
import { useEffect, useState } from 'react';
import * as React from "react";
import Sidebar from '../../../layout/Sidebar';
import checkAuth from '../../../middleware/checkAuth'
import {getData, deleteMood} from '../../../Services/Mood';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import {NavLink} from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
function Main() {

    const [list, setList] =  useState([]);
    const navigate = useNavigate();
    useEffect(()=>{
        async function data()
        {
            const data =   await getData();
            if( data.status == 400 ) { toast.error(data.msg) } else { setList(data.data); }
        }
        data()
    },[list]);
    const editpage = (id,list,key) =>
    {
        
        navigate("/user/mood/edit/"+id,{ state: { data:list,key:key} });
    }
    // Modal properties
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex:99,
        },
      };
    const [openModal, setOpenModal] = React.useState(false);
    const deleteModal = () => {
        setOpenModal(true);
    }
    const closeModal =() => {
        setOpenModal(false);
        localStorage.removeItem('delete_id');
    }
    const deleteItem = async () => {
        setOpenModal(false);
        const data =   await deleteMood();
        if( data.status == 400 ) { toast.error(data.msg) } else { toast.success(data.msg) }        
        localStorage.removeItem('delete_id');
    }
    // Modal properties

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />          
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                                <div className="intro-y  items-center h-10">
                                    <h2 className="text-lg font-medium truncate mr-5 float-left">
                                         Moods
                                    </h2>
                                    <NavLink to='/user/mood/add' className="btn btn-primary-dark shadow-md mr-2 float-right">Add Mood </NavLink> 
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-2">
                                    
                                    <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                                        <table className="table table-report -mt-2">
                                            <thead>
                                                <tr>
                                                    <th className="whitespace-nowrap">Mood Name </th>
                                                    <th className="whitespace-nowrap">White (%)</th>
                                                    <th className="whitespace-nowrap">Yellow (%)</th>
                                                    <th className="whitespace-nowrap">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {list ? list.map((item, index) => (
                                                <tr className="intro-x" key={index}>  
                                                    <td>{item['MoodName']}</td>
                                                    <td>{item['White']}</td> 
                                                    <td>{item['Yellow']}</td> 
                                                    <td className="table-report__action w-56">
                                                        <div className="flex"> 
                                                            <button className='mr-3' onClick={()=>editpage(item['Id'],list,index)} key={index} ><FontAwesomeIcon icon={faEdit}  size="1x" /> Edit</button> 
                                                            <a
                                                                        className="flex items-center text-danger" href='#'
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            localStorage.setItem('delete_id', item['Id']);
                                                                            deleteModal();
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon className='mr-1' icon={faTrash} size="1x" />  Delete
                                                                    </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : " " }                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <Modal
        isOpen={openModal}
        className='modalC'
        contentLabel="Example Modal"
      >
        
        <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Delete Desk</h1>
        </div>
        <div class="modal-body text-center">
          <p>Are you sure want to delete?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary mr-5 " onClick={closeModal}>Close</button>
          <button type="button" class="btn btn-primary-dark" onClick={deleteItem }> Yes changes</button>
        </div>
      </div>

      </Modal>


        </>
    );

}
export default checkAuth(Main,'user');
import * as React from "react";
import { useEffect, useState } from 'react';
import images from '../../../Constants/image'
import Sidebar from '../../../layout/SidebarAdmin';
import checkAuth from '../../../middleware/checkAuth'
import { get,create } from '../../../Services/Admin/Setting';
import Loader from '../../Loader/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useLocation, useParams  } from "react-router-dom";
function Main() {
    

    const [businessStartHours, setBusinessStartHours] = useState();
    const [compType, setCompType] = useState();
    
    
    const [loader, setLoader] = useState(false);
    
    const [globalLoader, setGlobalLoader] = useState(true);
    async function getHours() 
    {
        
        const data = await get();
        if (data.status == 400) { toast.error(data.msg) } else { setBusinessStartHours(data.data.BusinessStartHours);setCompType(data.data.CompType);setGlobalLoader(false);  }
        
    }
    useEffect( ()=>{
        getHours();
    },[globalLoader])
 
    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault()
        
        
        const data = {"BusinessStartHours":e.target.businessStartHours.value, "CompType":compType};
        const res = await create(data);
        res.status == 200 ? toast.success(res.msg) : toast.error(res.msg) ;
        if (res.status == 200)
        {
            localStorage.setItem('comptype',compType);
        }
        setLoader(false); 
        
    }


    return (
        <>
        <Loader toggle={globalLoader} />
        <ToastContainer  position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />          
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                                <div className="intro-y flex items-center h-10">
                                    <h2 className="text-lg font-medium truncate mr-5">
                                    Settings
                                    </h2>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-5">
                                    <div className="intro-y col-span-12 lg:col-span-6">


                                        <div className="intro-y box">
                                            <div id="input" className="p-5 table w-full">
                                                <div className="preview">
                                                    <form onSubmit={handleSubmit}>
                                                        <div>
                                                            <label htmlFor="regular-form-1" className="form-label"> Business Start Hours <font color='red'> *</font></label>
                                                            <select className="form-control" value={businessStartHours}  onChange={(e)=>setBusinessStartHours(e.target.value)} name="businessStartHours">
                                                                <option value='6'>6</option>
                                                                <option value='7'>7</option>
                                                                <option value='8'>8</option>
                                                                <option value='9'>9</option>
                                                                <option value='10'>10</option>
                                                            </select>
                                                        </div>   

                                                        <div>
                                                            <label htmlFor="regular-form-1" className="form-label"> Company Type <font color='red'> *</font></label>
                                                            <select className="form-control" value={compType}  onChange={(e)=>setCompType(e.target.value)} name="compType">
                                                                <option value='1'>Large Company</option>
                                                                <option value='2'>Small Company</option>
                                                            </select>
                                                        </div>

                                                        <div className="mt-3">
                                                            <button type="submit" className="btn btn-primary-dark shadow-md mr-2 float-left">Submit</button>
                                                            { loader ? 
                                                                    (
                                                                        <img className="ml-2 pt-1" src={`${images.loader}`} width={30}/>
                                                                ) : ' '
                                                            }
                                                            

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

}
export default checkAuth(Main,'admin');
import * as React from "react";
import { useRoutes } from "react-router-dom";
import Login from '../Components/Login';
import Quote from '../Components/Quote';
import Dashboard from '../Components/User/Dashboard';

import DeskList from '../Components/User/Desklist';
import DeskAdd from '../Components/User/Deskadd';


import Useradd from '../Components/Admin/Useradd';
import Userlist from '../Components/Admin/Userlist';

import DeskAdminList from '../Components/Admin/Desklist';
import DeskAdminAdd from '../Components/Admin/Deskadd';
import AdminSettings from '../Components/Admin/Setting';


import MoodList from '../Components/User/Moodlist';
import MoodAdd from '../Components/User/Moodadd';
import Settings from '../Components/User/Settings';
import UpdateFirmware from '../Components/Admin/UpdateFirmware';

import GenerateQuote from '../Components/Quote/GenerateQuote';
import BuildingType from '../Components/Quote/BuildingType';
import ConstructionStage from '../Components/Quote/ConstructionStage';
import AddSpaces from '../Components/Quote/AddSpaces';
import Summary from '../Components/Quote/Summary';
import ContactForm from '../Components/Quote/ContactForm';
import EmailForm from '../Components/Quote/EmailForm';



import DashboardAdmin from '../Components/Admin/Dashboard';

// import "../Assets/css/globals.css";
// import "../Assets/css/style.css";



function Router()
{
    const routes = [
        {
            path: "/user",
            children: [ 
                {
                    path: "/user",
                    element: <Settings />,
                  },
                {
                    path: "dashboard",
                    element: <Settings />,
                },
                {
                    path: "desks",
                    element: <DeskList />,
                },           
                {
                    path: "desk/add",
                    element: <DeskAdd />,
                },
                {
                    path: "desk/edit/:id",
                    element: <DeskAdd />,
                },
                {
                    path:"moods",
                    element:<MoodList />,
                },
                {
                    path:"mood/add",
                    element:<MoodAdd />
                },
                {
                    path:"mood/edit/:id",
                    element:<MoodAdd />
                },
                {
                    path:"settings",
                    element:<Settings />
                }
            ],

        },
        {
            path: "/admin",
            children:[
                {
                    path: "/admin",
                    element: <Userlist />,
                },
                {
                    path: "setting",
                    element: <AdminSettings />,
                },                
                
                {
                    path: "user",
                    element: <Userlist />,
                },
                {
                    path: "user/add",
                    element: <Useradd />,
                },
                {
                    path: "user/edit/:id",
                    element: <Useradd />,
                },
                {
                    path: "desk",
                    element: <DeskAdminList />,
                },
                {
                    path: "desk/add",
                    element: <DeskAdminAdd />,
                },
                {
                    path: "desk/edit/:id",
                    element: <DeskAdminAdd />,
                },
                {
                    path:"firmware",
                    element:<UpdateFirmware />
                }                
            ],

        },
        {
            path: "/generatequote",
            element: <GenerateQuote />,
        },        
        {
            path: "/",
            element: <GenerateQuote />,
        },
        {
            path: "/buildingtype",
            element: <BuildingType />,
        }, 
        {
            path: "/construction-stage",
            element: <ConstructionStage />,
        }, 
        {
            path: "/add-spaces",
            element: <AddSpaces />,
        }, 
        {
            path: "/summary",
            element: <Summary />,
        }, 
        {
            path: "/contactus",
            element: <ContactForm />,
        },
        {
            path: "/emailform",
            element: <EmailForm />,
        },                
        {
            path: "/login",
            element: <Login />,
        },       
        {
            path: "/Quote",
            element: <Quote />,
        },
    ]
    return useRoutes(routes);

}
export default Router
import * as React from "react";
import images from '../../Constants/image'
import {NavLink,useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDashboard,faFaceSmile, faArrowRight, faGear,faRuler,faRightFromBracket, faUserAlt, faKey, faGears } from '@fortawesome/free-solid-svg-icons'
function Main({toggle}) {
     
    console.log(toggle);
    
    return (
        <>
        {
            toggle ? 
                (
                    <div class="loading">Loading&#8230;</div>
                )
            : ''
        }
        </>
    );

}
export default Main;


import * as React from "react";
import { useEffect, useState } from 'react';
import images from '../../../Constants/image'
import Sidebar from '../../../layout/SidebarAdmin';
import checkAuth from '../../../middleware/checkAuth'
import {add} from '../../../Services/Admin/Desk';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Loader/loader';
import { useNavigate,useLocation, useParams  } from "react-router-dom";
function Main() {
    
    const navigate = useNavigate();
    const params = useParams();
    const {state} = useLocation();

    const [name, setName] = useState('');
    
    const [active, setActive] = useState(true);
    const [loader, setLoader] = useState(false);
    const [submitType, setSubmitType] = useState('0');
    const [globalLoader, setGlobalLoader] = useState(true);
    useEffect(()=>{
        setGlobalLoader(false);
        if(state!=null) { setSubmitType(state.data.Id); setName(state.data.Name);setActive(state.data.Status);  } 
    },[globalLoader])
 
    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault()
        
        
        const userId = localStorage.getItem('id') != null  ? localStorage.getItem('id') :  navigate("/login");
        
        const data = {"Id":submitType,"Name":e.target.name.value,"Status":e.target.active.value};
        const res = await add(data);
        res.status == 200 ? toast.success(res.msg) : toast.error(res.msg) ;
        setLoader(false); 
        if (submitType == 0 && res.status == 200) { setName('');  setActive('');  }
    }


    return (
        <>
        <Loader toggle={globalLoader} />
        <ToastContainer  position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />          
        <div className="flex overflow-hidden">
        <Sidebar />
            <div className="content">
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 2xl:col-span-9">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 mt-8">
                                <div className="intro-y flex items-center h-10">
                                    <h2 className="text-lg font-medium truncate mr-5">
                                    {submitType==0?'Add':'Update'} Desk
                                    </h2>
                                </div>
                                <div className="grid grid-cols-12 gap-6 mt-5">
                                    <div className="intro-y col-span-12 lg:col-span-6">


                                        <div className="intro-y box">
                                            <div id="input" className="p-5 table w-full">
                                                <div className="preview">
                                                    <form onSubmit={handleSubmit}>
                                                        <div>
                                                            <label htmlFor="regular-form-1" className="form-label"> Name<font color='red'> *</font></label>
                                                            <input value={name}  onChange={(e)=>setName(e.target.value)} name="name" id="regular-form-1" type="text" className="form-control" maxLength="15"  placeholder="Desk Name"  required />
                                                        </div>
                                                        <div className="mt-3 position-relative">
                                                            <label htmlFor="regular-form-1" className="form-label">Status<font color='red'> *</font></label>
                                                            <select required  value={active} onChange={(e)=>setActive(e.target.value)}  name="active" id="regular-form-2" className="form-control">
                                                                <option value="">Select Status</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>                                                               
                                                            </select> 
                                                        </div>                                                          
                                                        <div className="mt-3">
                                                            <button type="submit" className="btn btn-primary-dark shadow-md mr-2 float-left">Submit</button>
                                                            { loader ? 
                                                                    (
                                                                        <img className="ml-2 pt-1" src={`${images.loader}`} width={30}/>
                                                                ) : ' '
                                                            }
                                                            

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

}
export default checkAuth(Main,'admin');
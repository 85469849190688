import React, { useState } from 'react';
import axios from 'axios';

function EmailForm() {
  const [formData, setFormData] = useState({
    to: '',
    subject: '',
    text: '',
    attachment: null,
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachment: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('to', formData.to);
    data.append('subject', formData.subject);
    data.append('text', formData.text);
    data.append('attachment', formData.attachment);

    try {
      const response = await axios.post('http://127.0.0.1:7897/send-email', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>To:</label>
        <input
          type="email"
          name="to"
          value={formData.to}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Subject:</label>
        <input
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Message:</label>
        <textarea
          name="text"
          value={formData.text}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Attachment:</label>
        <input type="file" onChange={handleFileChange} />
      </div>
      <button type="submit">Send Email</button>
    </form>
  );
}

export default EmailForm;

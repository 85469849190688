import React, { Fragment, useState, useEffect , useContext } from 'react'
import DataContext from '../../context/DataContext';
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import logo from '../../Assets/img/logo.png'
import {generateId,styles } from '../../Common/utils'


const BomTable = ({ areaControllingItems, selectedItems, selectedbrand, essentialItem }) => {
  const [bom, setBom] = useState([]);
  const [miscellaneousItems, setMiscellaneousItems] = useState([]);
  const [optionalItems, setOptionalItems] = useState([]);

  // Create the BOM based on conditions and group by Name
  const createBOM = () => {
    const miisItems = selectedItems.filter(
        (selectedItem) =>
            selectedItem.ItemTypeCode === 'miit'
      );
      setMiscellaneousItems(miisItems);

      const opitItems = selectedItems.filter(
        (selectedItem) =>
            selectedItem.ItemTypeCode === 'opit'
      );
      setOptionalItems(opitItems);

      const newBom = areaControllingItems.reduce((acc, controlItem) => {
          const matchingItems = selectedItems.filter(
              (selectedItem) =>
                  selectedItem.ItemCode === controlItem.AreaItemCode &&
                  controlItem.BrandCode === selectedbrand //selected brand
          );       
          
          
          const totalQuantity = matchingItems.reduce(
              (sum, item) => sum + item.Quantity, 0
          );

          if (totalQuantity > 0) {

                const existingItem = acc.find(item => item.Name === controlItem.ControllingItemName);
                
                if (existingItem) {
                    // If the item with the same name already exists, sum the quantities
                    existingItem.Quantity += controlItem.ControllingItemQuantity * totalQuantity;
                } else {
                    // Otherwise, add a new item to the bom
                    acc.push({
                        Id: generateId(),
                        Name: controlItem.ControllingItemName,
                        Quantity: controlItem.ControllingItemQuantity * totalQuantity
                    });
                }
          }

          return acc;
      }, []);

      setBom(newBom);
  };

  // Call createBOM when the component mounts or brandId changes
  React.useEffect(() => {
      createBOM();
  }, [selectedbrand, selectedItems]);

  return (
      <>
          <h2>BOM (Bill of materials)</h2>
          {bom.length > 0 ? (
              <table>
                  <thead>
                      <tr style={styles.summaryitemtotal}>
                          <th>Name</th>
                          <th style={styles.trRightAlign}>Quantity</th>
                      </tr>
                  </thead>
                  <tbody>
                         <tr>
                              <td>{essentialItem.ItemName}</td>
                              <td style={styles.trRightAlign}>1</td>
                         </tr>
                      {bom.map((item) => (
                          <tr key={item.Id}>
                              <td>{item.Name}</td>
                              <td style={styles.trRightAlign}>{item.Quantity}</td>
                          </tr>
                      ))}
                      {optionalItems.map((item) => (
                          <tr key={item.Id}>
                              <td>{item.ItemName}</td>
                              <td style={styles.trRightAlign}>{item.Quantity}</td>
                          </tr>
                      ))}
                       {miscellaneousItems.map((item) => (
                          <tr key={item.Id}>
                              <td>{item.ItemName}</td>
                              <td style={styles.trRightAlign}>{item.Quantity}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          ) : (
              <p>No BOM items found.</p>
          )}
      </>
  );
};

export default BomTable
import logo from '../Assets/images/logo.svg';
import illustration from '../Assets/images/illustration.svg';
import profile from '../Assets/images/profile-6.jpg';
import loader from '../Assets/images/loader.gif';
import automateologo from '../Assets/images/automateologo.png';
import house from '../Assets/img/frame-8.svg';
import househover from '../Assets/img/frame-8-hover.svg';
import apartment from '../Assets/img/frame-5.svg';
import apartmenthover from '../Assets/img/frame-5-hover.svg';
import commercial from '../Assets/img/frame-3.svg';
import commercialhover from '../Assets/img/frame-3-hover.svg';
import industrial from '../Assets/img/frame-1.svg';
import industrialhover from '../Assets/img/frame-1-hover.svg';
import yetstart from '../Assets/img/YetStart.svg';
import yetstarthover from '../Assets/img/YetStart-hover.svg';
import progress from '../Assets/img/Progress.svg';
import progresshover from '../Assets/img/Progress-hover.svg';
import almostcomplete from '../Assets/img/AlmostComplete.svg';
import almostcompletehover from '../Assets/img/AlmostComplete-hover.svg';
import next from '../Assets/img/frame-15.svg';
import offac from '../Assets/img/off-ac.svg';
import offheater from '../Assets/img/off-heater.svg';
import br1 from '../Assets/img/BR1.png';
import br2 from '../Assets/img/BR2.png';
import br3 from '../Assets/img/BR3.png';
import s1 from '../Assets/img/S1.png';
import b1 from '../Assets/img/B1.png';
import w1 from '../Assets/img/W1.png';
import w2 from '../Assets/img/W2.png';
import w3 from '../Assets/img/W3.png';
import k1 from '../Assets/img/K1.png';
import l1 from '../Assets/img/L1.png';
import h1 from '../Assets/img/H1.png';
import offbulb from '../Assets/img/off-bulb.svg';
import offtv from '../Assets/img/off-tv.svg';
import lock from '../Assets/img/Lock.svg';
import home from '../Assets/img/home.svg';
import pump from '../Assets/img/i2.svg';
import maingate from '../Assets/img/i3.svg';
import maindoorlock from '../Assets/img/i1.svg';
import centraltouchpanel from '../Assets/img/i4.svg';
import watertanklevelsensor from '../Assets/img/i5.svg';
import generator from '../Assets/img/i6.svg';
import inverter from '../Assets/img/i7.svg';
import viewsummery from '../Assets/img/view-summery.svg';
import ca from '../Assets/img/CA.svg';
import cap from '../Assets/img/CAP.svg';
import ch from '../Assets/img/CH.svg';
import chp from '../Assets/img/CHP.svg';

import wohn from '../Assets/img/wohn.png';
import wohnhover from '../Assets/img/wohn-hover.png';

import elkr from '../Assets/img/elkr.png';
import elkrhover from '../Assets/img/elkr-hover.png';

import eco from '../Assets/img/eco.png';
import ecohover from '../Assets/img/eco-hover.png';











const images = 
{
    logo:logo,
    illustration:illustration,
    profileImg:profile,
    loader:loader,
    automateologo:automateologo,
    house:house,
    househover:househover,
    apartment:apartment,
    apartmenthover:apartmenthover,
    commercial:commercial,
    commercialhover:commercialhover,
    industrial:industrial,
    industrialhover:industrialhover,
    yetstart:yetstart,
    yetstarthover:yetstarthover,
    progress:progress,
    progresshover:progresshover,
    almostcomplete:almostcomplete,
    almostcompletehover:almostcompletehover,
    next:next,
    offac:offac,
    offheater :offheater,
    br1:br1,
    br2:br2, 
    br3:br3,
    s1:s1,
    b1:b1,w1:w1,w2:w2,w3:w3,k1:k1,l1:l1,
    h1:h1,
    offbulb:offbulb,
    offtv:offtv,
    lock:lock,
    home:home,pump:pump,maingate:maingate,maindoorlock:maindoorlock,
    centraltouchpanel:centraltouchpanel,watertanklevelsensor:watertanklevelsensor,
    generator:generator,inverter:inverter,
    viewsummery:viewsummery,
    ca:ca,cap:cap,ch:ch,chp:chp,

    wohn:wohn, wohnhover:wohnhover,
    elkr:elkr, elkrhover:elkrhover,
    eco:eco, ecohover:ecohover

}

export default images;
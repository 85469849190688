import * as React from "react";
import axios from "axios";
import URL from '../../Constants/links';
import message from '../../Constants/message'
const get = async () =>
{
    try {
        const data = await axios.get(URL.api_url+URL.users)
        .then((data)=>
          {
            return {data:data.data};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.serverError};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


const create = async (json) =>
{
  
    try{
        const data = await axios.post(URL.api_url+URL.addUser,json)
        .then((res)=>
          {
            
            const msg = json.UserId == 0 ?  message.userInsert : message.userUpdated;
            return {data:res,msg:msg,status:200};
          }
        )
        .catch((error)=>
          {
            const msg = error.response.data != null ? error.response.data : '';   
          return {status:error.response.status,msg:msg};
            
          }
        );
        return data;
      } catch (error) {
        
        return {status:400,msg:message.serverError};
      }    
}


const destroy = async () =>
{
  
    try {
        const data = await axios.post(URL.api_url+URL.deleteUser,{UserId:localStorage.getItem('delete_id')})
        .then((res)=>
          {
            
            return {status:200,msg:message.userDeleted,data:res};
          }
        )
        .catch((error)=>
          {
            return {status:400,msg:message.userNotExist};
          }
        );
        return data;
      } catch (error) {
        return {status:400,msg:message.serverError};
      }    
}


export  {get,create,destroy};